import { Component } from "react";
import React from "react";

class NotFound extends Component {
	render() {
		return (
			<div>
                <div className="other-header">
                   
                </div>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="page-title-block">
								<h5 className="mt-4">The page you are looking for is</h5>
								<h1>Not Found</h1>
								<img className="mt-3 mb-5" src="/images/rotated-dekor.svg" />
								<div></div>
								<a href="/" className="subscribe-text">Go Home</a>
								<div className="m-5"></div>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-lg-9" align="left">
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;