import React, { Component } from "react";
import CarItem from "../containers/cars-item";

class Fleet extends Component {
    constructor() {
        super();
    }
    
    render() {
        window.scrollTo(0, 0);
        
        return (
            <div>

                <div className="section_1">
                    <div className="header-img fleet_page">
                        <h1>Our Licensed & Reliable Deluxe Fleet</h1>
                        {/* <a href="https://booking.vip-transfers.ch/" className="btn btn-style mt-4"> Book Now </a> */}
                    </div>
                </div>

                <div className="section_fleet">
                    <div className='container pb-4'>
                        <h2 className="section_title my-3 my-md-5">FLEET</h2>
                        
                        <p className="home_p">
                        Our deluxe fleet, featuring stylish brand-new vehicles, ensures a private and luxurious experience tailored to your desires. Experience the excellence of our first-class service, dedicated to making every moment of your journey exceptional from start to finish. </p>
            {/*             <p className="home_p">
                        VIP-Transfers has all that you are looking forward to having
                        the best holiday vacation in Switzerland while enjoying your
                        stay the most, all that on private and luxurious bases. Thanks
                        to its varied collection of a deluxe fleet which consists of
                        stylish brand new vehicles. <br />
                        <br />
                        VIP-Transfers cares about your private travel and
                        transportation, thus, we have the pleasure to serve you with
                        most new VIP cars. Besides, the arranged and provided service
                        is first class from the beginning until the end. <br />
                        </p> */}
                        
                        <div className="mb-4 mb-md-5"> 
                            <CarItem />
                        </div>

                            {/* <p className="home_p">
                            The company’s private transfers are available within all the
                            cantons of Switzerland and the neighbouring regions. <br />
                            <br />
                            Our fleet is legally registered and includes the eco-friendly
                            cars as Tesla Model X, all type of Mercedes Benz minivans,
                            sedans and minibuses. All that in order to take care of you
                            and your parties, whether you are accompanied by friends or
                            family members, we have all the suitable options of vehicles
                            to gather you in comfort. <br />
                            <br />
                            The very name of Mercedes is enough to know the delicacy of
                            the provided service. Inside it is a spacious and beautifully
                            appointed saloon, which elevating safety and silence, becoming
                            a byword for luxury. Being featured with 4MATIC all-wheel
                            drive to control every wheel all the time provides
                            overwhelming safety especially on rain and snow conditions.{" "}
                            <br />
                            <br />
                            The company’s main mission is always to provide its valuable
                            clients with high-quality services by making the ride
                            extremely secure and smooth at unbeatable rates. Whenever you
                            just book your transfer with VIP-Transfers, you will be
                            convinced of the exceptional provided services. Ensuring that
                            you will come back to book many more private transfers with
                            us. <br />
                            <br />
                            Those who value not only comfortable but also luxurious rides,
                            VIP-Transfers all along with its friendly staff and
                            professional drivers are here at their disposal in order to
                            offer different reliable choices for private transfers within
                            Switzerland operated by the classes of Mercedes, which stand
                            out with their unique ergonomics. <br />
                            <br />
                            Regardless of which vehicle you would like to book for your
                            private trip, all our fleet is fitted with free WiFi service
                            during your transfer’s time within Switzerland. Hence, you
                            will take advantage of this as well as to keep your beloved
                            ones updated with your news.
                            <br />
                            <br />
                            Moreover, just sit in comfort on the leather seats, in some of the minivans there is a unique preference of face to face seating and other sophisticated features the cars are equipped with allows you to be comfortably seated, relax and enjoy the quiet and smooth ride minimizing the road and wind noise even at the high speed. Also, do not forget in case you are accompanied by children to advise the ages so we will be able to supply the vehicle with the appropriate child seat.
                            <br />
                            <br />
                            Our elegant and modern fleet together with an extraordinary service certainly will meet and exceed all your expectations about luxury, comfort, tranquillity, and safety starting by greeting you and up to the ending of your trip.
                            <br />
                            <br />
                            Do not waste more time and just book your private airport or city transfer beside other services with VIP-Transfers and receive services equal to the Swiss standards.
                        </p> */}
                        
                        <p className="home_p"> Our private transfers are available throughout all cantons in Switzerland and neighboring countries. </p> 
                        <p className="home_p"> Vip-Transfers fleet includes eco-friendly Teslas, all type of Mercedes-Benz minivans, sedans and minibuses. The fleet also offers vehicles of luxurious class, such as Mercedes-Benz S class, EQV, and GLE. We have all suitable options of vehicles to make your travels as comfortable as possible. </p> 
                        <p className="home_p"> Enjoy free WiFi during your private trip within Switzerland, available in all vehicles across our fleet. Relax in comfort on leather seats, some with unique face-to-face seating in minivans. Our cars boast sophisticated features for a quiet and smooth ride, minimizing road and wind noise even at high speeds. Don’t forget to inform us about accompanying children, so we can provide the appropriate child seat. </p> 
                        <p className="home_p"> Don’t hesitate – book your private airport or city transfer with VIP-Transfers now and experience Swiss-standard excellence.</p>

                    </div>
                </div>
            </div>
        );
    }
}

export default Fleet;