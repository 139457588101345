import { Component } from "react";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "../helper/js/babel.js";
import { Router, Route, Link } from "react-router-dom";
import history from "../history";
import "../helper/css/header.css";

import "jquery/dist/jquery";
import "popper.js/dist/umd/popper";
import "bootstrap/dist/js/bootstrap";
import Logo from "../helper/images_logo/logo.png";
import { Navbar, NavItem, Section, SideNav, Button, SideNavItem, } from "react-materialize";
import { connect } from "react-redux";
import { I18n } from "react-i18nify";

class Header extends Component {
    render() {
        var lang = this.props.activeLang.code;
        I18n.setLocale(lang);
        const logoSrc = (this.props.headerClass=== 'header_dark')? 'logo' : 'logo-white';

        return (
            <div className={`header_parent_div ${this.props.headerClass}`}>
                <Router history={history}>
                    <div className="container link_to">
                        <a className="mobile_logo" href="/">
                            <img src={`/images/${logoSrc}.svg`} />
                        </a>
                        <Navbar className="menu_nav">
                            <ul
                                className="header_menu_ul"
                                itemScope
                                itemType="http://www.schema.org/SiteNavigationElement"
                            >
                                <li className="mobile_icon menu mb-3" id="icon">
                                    <a href="/">
                                        <img className="mobile_logo_img" src={`/images/logo.svg`} />
                                    </a>
                                </li>
                                <li className="menu" id="logo" itemProp="name">
                                    <a href="/">
                                        <img className="logo_img" src={`/images/${logoSrc}.svg`} alt="Logo" />
                                    </a>
                                </li>
                                {/* <li className="menu" id="home" itemProp="name">
                                    <a href="/" itemProp="url">
                                        Home
                                    </a>
                                </li> */}
                                <li className="menu" id="about" itemProp="name">
                                    <a href="/about" itemProp="url">
                                        About Us
                                    </a>
                                </li>
                                <li className="menu" id="services" itemProp="name">
                                    <a href="/services" itemProp="url">
                                        Services
                                    </a>
                                </li>
                                <li className="menu" id="fleet" itemProp="name">
                                    <a href="/fleet" itemProp="url">
                                        Fleet
                                    </a>
                                </li>
                                <li className="menu" id="contact" itemProp="name">
                                    <a href="/contact" itemProp="url">
                                        Contact
                                    </a>
                                </li>

                                <li className="menu" id="contact" itemProp="name">
                                    <a href="https://booking.vip-transfers.ch/"
                                        className="btn effecto"> Book Now
                                    </a>
                                </li>
                            </ul>


                        </Navbar>
                        <div className="nav-wrap">
                            <div className="underline"></div>
                        </div>
                    </div>
                </Router>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.activeLang,
});

export default connect(mapStateToProps)(Header);