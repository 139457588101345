import React from 'react';
import ReactDOM from 'react-dom';

import {createStore} from 'redux';
import {Provider} from 'react-redux';
import AllReducers from './reducers/index'
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import languages from './locale/languages';
import {I18n} from "react-i18nify";


let tr = languages;

const translations = () => tr;

I18n.setTranslationsGetter(translations);


const store=createStore(AllReducers);


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'));

serviceWorker.unregister();


