import { Component } from "react";
import React from "react";
import HTMLrender from 'react-render-html'
import Carousel from 'nuka-carousel';
// import Content from "../components/partialsCD/Content";
import connect from "react-redux/es/connect/connect";
import {I18n} from "react-i18nify";

class Car extends Component {
    constructor(props) {
        super(props);
        this.state = {
            car: I18n.t('cars')[0],
            autoplay: true,
        };
        this.carGaleryImages=this.carGaleryImages.bind(this)
    }


        
    carFeatures(cf){
        const  features_array=  I18n.t("features");

        if (cf != null) {
            return Object.keys(cf).map((index) => {
                const featureName = cf[index];
                 const featureArray = Object.values(features_array);
                const matchingFeature = featureArray.find((feature) => feature.name === featureName);
                const featureText = matchingFeature ? matchingFeature.text : featureName;
                const featureIcon = matchingFeature ? matchingFeature.name : 'noIcon';

                return <div key={'f' + index} className="car_feature mr-5 mb-2">                    
                    <img key={'img'+index} src={"/images/cars/features/"+featureIcon+".svg"}/>
                    <span className="car_feature_text ml-2">{featureText}</span>
                </div>;
            });
        }
    }


    componentDidMount() {
        var url = window.location.pathname;
        var myarr = url.split("/");
        const cars = I18n.t("cars");

        if (myarr[2] != undefined) {
            Object.keys(cars).map((index) => {
                if (cars[index].title_url === myarr[2])
                    this.setState({
                        car: cars[index],
                    });
            });
        } else {
            // Does not exist
        }
    }


            
    carGaleryImages(){
        let car = this.props.activeCar;        

         let type = car ? car.type : null;
        if (type && type == "CHANGE_CONTENT_CAR") {
            car = car.points;
        } else {
            car = this.state.car;
        }
        

        if(car != null){
            return  Object.keys(car.galeyiImg).map((index) => {
                return   <img key={'img'+index} src={car.galeyiImg[index].url}/>
            })
        }
    }



            

    render() {
        window.scrollTo(0, 300);
         let car = this.props.activeCar;

        let type = car ? car.type : null;
        if (type && type == "CHANGE_CONTENT_CAR") {
            car = car.points;
        } else {
            car = this.state.car;
        }

        return (
            <div>            
                <div className="fleet_car_slider">
                    <div className='container w-100-mob'>
                        <Carousel autoplay={true}
                                autoplayInterval={2500}
                                pauseOnHover={true}
                                easing = "easeQuadInOut"
                                wrapAround={true}
                                renderCenterLeftControls={({ previousSlide }) => (
                                    <button onClick={previousSlide}><i className="material-icons">keyboard_arrow_left</i></button>
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <button onClick={nextSlide}><i className="material-icons">keyboard_arrow_right</i></button>
                                )}
                                >
                            {this.carGaleryImages()}
                        </Carousel>
                    </div>
                </div>


                <div className="container py-4 py-md-5">

                    <h5 className="text-muted">{car.type}</h5>

                    <h1 className="section_title mt-md-4 mt-0 mb-0">{ car.title }</h1>

                    <div className="car_options d-flex justify-content-between px-1" style={{maxWidth: "300px"}}>
                        <div className="car_option">
                            <img src="/images/cars/icons/pax_icon.svg" />
                            <span >{car.pax}</span>
                        </div>
                        <div className="car_option">
                            <img src="/images/cars/icons/lug_icon.svg" />
                            <span >{car.lug}</span>
                        </div>
                        <div className="car_option">
                            <img src="/images/cars/icons/big_lug.svg" />
                            <span >{car.lug}</span>
                        </div>
                    </div>
                    <hr className="mt-2" />

                    <div className="d-flex flex-wrap mb-4 mb-md-5">
                        { this.carFeatures(car.features) } 
                    </div>
                    


                
                    <p className="home_p">{HTMLrender(car.text_top)}</p>

                    <a href="https://booking.vip-transfers.ch/" className="btn effecto  fish white_fish mt-4"> Book Now </a>

                    <div className='py-4'></div>

                </div>


                {/*<CarItem />
                 <Content flag="car" car={car} /> */}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
        activeCar: state.actions,
    };
}

export default connect(mapStateToProps)(Car);