import {Component} from "react";
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import {I18n} from "react-i18nify";
import HTMLrender from 'react-render-html';

class Exclusive extends Component {

    handleClick = (url) => {
        window.location.href = url;
    }

    render () {

            return (

                <div>
                    <div className="section_1">
                        <div className="header-img about_page">
                            <h1>EXCLUSIVE CARS</h1>
                            <a href="https://booking.vip-transfers.ch/" className="btn btn-style fish mt-4"> Book Now </a>
                        </div>
                    </div>

                    <div className='container my-5'>
                        <p className="home_p">Welcome to a new level of luxury travel, where every journey is defined by style and comfort. Enter a world of elegance as you explore this exclusive collection of Mercedes-Benz luxury cars. From the moment you step into one of these exclusive vehicles, you’ll feel the luxury and sophistication. The experience is complete with the help of professional drivers who are committed to delivering first-class service, ensuring that every transfer is smooth and unforgettable.</p>
                    </div>
    
                    <div className="container-fluid">
                        <div className="container w-100-mob pb-5">
    
    
                        <h2 className="section_title my-3">MERCEDES-BENZ S500 FIRST CLASS</h2>
                            <div className="row mb-5">
                                <div className="col-md-8" onClick={() => this.handleClick('/car/mb_s_500')} style={{ cursor: 'pointer' }}>
                                    <img className="fill_img" src="/images/exclusive/s-ex.jpg"></img>
                                </div>
                                <div className="col-md-4">
                                    <p className="car_info">
<img src="https://alptransfer.com/images/icons/VIP-vehicle-white.svg" alt="VIP-vehicle"></img>
                                        VIP-vehicle	<br/><br/>
<img src="https://alptransfer.com/images/icons/4matic-white.svg" alt="4Matic"></img>
                                        4Matic<br/><br/>
<img src="https://alptransfer.com/images/icons/leather-seats-white.svg" alt="Leather seats"></img>
                                        Leather seats<br/><br/>
<img src="https://alptransfer.com/images/icons/panoramic-windshield-white.svg" alt="panoramic-windshield"></img>
                                        Panoramic windshield<br/><br/>
<img src="https://alptransfer.com/images/icons/massage-seats-white.svg" alt="Massage seats"></img>
                                        Massage seats<br/><br/>
<img src="https://alptransfer.com/images/icons/heating-vantilation-seats.svg" alt="Heating seats"></img>
                                        Heating seats<br/><br/>
<img src="https://alptransfer.com/images/icons/heating-vantilation-seats.svg" alt="Ventilated seats"></img>
                                        Ventilated seats<br/><br/>
<img src="https://alptransfer.com/images/icons/air-conditioned-white.svg" alt="Air-conditioned"></img>
                                        Air-conditioned<br/><br/>
<img src="https://alptransfer.com/images/icons/wifi-white.svg" alt="WiFi on board"></img>
                                        WiFi on board<br/><br/>
<img src="https://alptransfer.com/images/icons/bottle-white.svg" alt="Mineral water"></img>
                                        Mineral water
                                    </p>
                                </div>
                            </div>
                                
    
    
<h2 className="section_title text-left my-3">MERCEDES-BENZ V CLASS EXCLUSIVE</h2>
                            <div className="row">
                                <div className="col-md-8" onClick={() => this.handleClick('/car/mb_v_exclusive')} style={{ cursor: 'pointer' }}>
                                    <img className="fill_img" src="/images/exclusive/v-ex.jpg"></img>
                                </div>
                                <div className="col-md-4">
                                    <p className="car_info">
<img src="https://alptransfer.com/images/icons/VIP-vehicle-white.svg" alt="VIP-vehicle"></img>
                                        VIP-Minivan	<br/><br/>
<img src="https://alptransfer.com/images/icons/4matic-white.svg" alt="4Matic"></img>
                                        4Matic<br/><br/>
<img src="https://alptransfer.com/images/icons/reclyning-seats-white.svg" alt="Reclining seats"></img>
                                        Reclining seats<br/><br/>
<img src="https://alptransfer.com/images/icons/automativ-doors-white.svg" alt="Automatic doors"></img>
                                        Automatic doors<br/><br/>
<img src="https://alptransfer.com/images/icons/massage-seats-white.svg" alt="Massage seats"></img>
                                        Massage seats<br/><br/>
<img src="https://alptransfer.com/images/icons/fridge-white.svg" alt="Refrigerator"></img>
                                        Refrigerator<br/><br/>
<img src="https://alptransfer.com/images/icons/220-230-volt.svg" alt="230V power slot"></img>
                                        230V power slot<br/><br/>
<img src="https://alptransfer.com/images/icons/air-conditioned-white.svg" alt="Air-conditioned"></img>
                                        Air-conditioned<br/><br/>
<img src="https://alptransfer.com/images/icons/wifi-white.svg" alt="WiFi on board"></img>
                                        WiFi on board<br/><br/>
<img src="https://alptransfer.com/images/icons/coffee-warmer.svg" alt="Coffee warmer"></img>
                                        Coffee warmer<br/><br/>
<img src="https://alptransfer.com/images/icons/bottle-white.svg" alt="Mineral water"></img>
                                        Mineral water
                                    </p>
                                </div>
                            </div>
                                
                                   
                        </div>
                    </div>


                </div>
            )
        

    }
}

export default Exclusive;