import { Component } from "react";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { I18n } from "react-i18nify";
import { Link } from "react-router-dom";
import { actionServices } from "../actions/index";

class HomeServices extends Component {

    render() {
        const services = I18n.t("services");

          return (
            <div className="row">
                {Object.keys(services).map((index) => (
                    <div className={ this.props.col +" service-block service-block-" + index}
                        key={"col" + index} >

                        <div 
                            onClick={() => this.props.service(services[index])} 
                            key={"card" + index} >
                            <Link to={{ pathname: "/service" + "/" + services[index].title_url,
                                    state: { service: services[index], allervices: services },
                                }} >
                                <div key={"card-content" + index} className="card-content">
                                    <img  key={"img" + index} src={services[index].icon} />

                                    <h3 key={"h3" + index}>{services[index].title}</h3>
                                    <p key={"p" + index}>{ services[index].text }</p>
                                    <div className="d-flex card_more">
                                        <p>More </p>
                                        <img className="chevrons-right ml-2" src="/images/chevrons-right.svg" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div> )
                )}

            </div>
          ) 
    }
}


function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ service: actionServices }, dispach);
}


export default connect(mapStateToProps, matchDispatchToProps)(HomeServices);
