import { Component } from "react";
import React from "react";
import { Button, Card, Row, CardTitle, Icon, Input } from "react-materialize";
import HomeServices from "../containers/home-services";
import HomeServicesMob from "../containers/home-services-mob";
import CarItem from "../containers/cars-item";
import HTMLrender from "react-render-html";
import "../helper/js/babel.js";
import {
    fetchMailContact,
    fetchMailNewsLetter,
} from "../services/RequestService";
import { I18n } from "react-i18nify";
import imgCar from "../helper/images_logo/vclass.png";
import covid_icon from "../helper/images_logo/covid_icon.svg";
import ratesImg from "../helper/images_logo/rates.svg";
import { Link } from "react-router-dom";
import $ from "jquery";

class Home extends Component {
    constructor(props) {
        super(props);

        this.sendMailSubscribe = this.sendMailSubscribe.bind(this);
        this.state = {
            alert: false,
            alertMessage: "These fields are required",
            alertClass: "alert-danger",
            name: "",
            email: "",
        };
    }

    handleChangeName = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    handleChangeEmail = (event) => {
        this.setState({
            email: event.target.value,
        });
    };

    sendMailSubscribe(e) {
        e.preventDefault();
        var email = this.mail.state.value;
        var first_name = this.name.state.value;

        let error = false;
        if ($(".error")) $(".error").remove();

        $(".required").each(function () {
            var id = $(this).attr("id");
            if (!$(this).val()) {
                $("#" + id)
                    .parent()
                    .append(
                        " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
                    );
                error = true;
            }
            if (id == "mail" && $("#mail").val()) {
                var emailValid = $("#mail")
                    .val()
                    .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                if (!emailValid) {
                    $("#" + id)
                        .parent()
                        .append(
                            " <div className='error' style='width:100%;color: #ca0505'>Please enter a valid email address</div>"
                        );
                    error = true;
                    return false;
                }
            }
        });

        if (email && first_name && !error) {
            var data = {
                email: email.replace(/\./g, "/"),
                name: first_name.split(" ").join("/"),
                url: "sendMailNewsLetter",
            };

            fetchMailContact(data).then((result) => {
                if (result && result.message == "success") {
                    console.log(result);
                    const successMessage = I18n.t("contact_success_message");
                    this.setState({
                        alertMessage: "You have been successfully subscribed",
                        alertClass: "alert-success",
                        alert: true,
                        name: " ",
                        email: " ",
                    });
                }
            });
        }
    }

    render() {
        const car_icon = {
            width: "30px",
            backgroundRepeat: "no-repeat",
            backgroundImage: "url(" + imgCar + ")",
            backgroundSize: "contain",
        };
        var style = {
            display: "none",
        };
        if (this.state.alert) {
            style = {
                display: "block",
            };
        }
        return (
            <div className="home_page">
                <div className="section_1">
                    <div className="header-img">
                        {/* <div className="overlay"></div> */}
                        <h1>Welcome to VIP-Transfers:</h1>
                        <p>Your Premier Swiss Travel Solution</p>
                        <a href="https://booking.vip-transfers.ch/" className="btn btn-style fish mt-4">
                            Book Now
                        </a>
                    </div>
                </div>


                
                <div className="section_fleet">
                    <div className="container py-3">
                        <h2 className="section_title">
                            Our Deluxe Fleet
                        </h2>

                        <p className="home_p"> Discover the ultimate luxury vacation with VIP-Transfers in Switzerland. Enjoy a private and lavish experience with our deluxe fleet of stylish, brand-new vehicles. Our commitment to excellence ensures a first-class service from start to finish, making your travel and transportation truly VIP.
                        </p>
                        <CarItem />
                    </div>
                    
                    <h2 className="section_title my-3">Services</h2>
                </div>


                <div className="section_services">
                    <div className="container w-100-mob">
                        <div className="position-relative d-none d-md-block">
                            <div className="row">
                                <div className="col-lg-9">
                                    <HomeServices  text="desc"  col="col-lg-6" />
                                </div>
                            </div>
                        </div>                            

                        <div className="position-relative d-block d-md-none">
                            <HomeServicesMob />
                        </div>
                    </div>
                </div>
                

                <h2 className="section_title text-center my-5">About Us</h2>

                <div className="section_about bg_fluid">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 px-0"> </div>
                            
                            <div className="col-lg-6 text-left">
                                <h2 className="section_subtitle text-left mb-3">Swiss Quality Private Transportation Services</h2>

                                <p className="home_p">VIP-Transfers, a luxury transportation company based in Fribourg, Switzerland, is a distinguished branch of ARATOURS SA, a seasoned player in the travel and transfer industry. With over 15 years of experience, our company specializes in private airport transfers, premium car at disposal services, and point-to-point private transfers throughout Switzerland, all facilitated by our VIP fleet.
                                </p> 
                                <p className="home_p">As an ever-evolving company, VIP-Transfers excels in providing diverse service packages. Our high reputation is built upon around-the-clock premium-class transportation services, earning the trust and satisfaction of our customers. We provide top-notch service at competitive rates, making us the preferred choice for travelers who value luxury and reliability.</p>
                                <a href="/about" className="btn effecto mt-4"> Read More </a>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="p-4"></div>



                <div className="section_welcome">
                    <h2 className="section_title">Welcome to VIP-Transfers</h2>

                    <h2 className="section_subtitle mb-5">Your gateway to luxurious travel in Switzerland!</h2>
                                
                    <div className="bg_fluid">    
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6" > </div>
    
                                <div className="col-lg-6">
                                    <p className="home_p ">
                                        {HTMLrender(I18n.t("home_welcome_text"))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="section_exclusive bg_fluid">    
                    <div className="container pb-5">
                        <div className="row mb-0">
                            <div className="col-lg-6" > </div>

                            <div className="col-lg-6">
                                <h2 className="section_title">Exclusive Cars</h2>
                                <p className="home_p ">
                                    {HTMLrender(I18n.t("home_exclusive_text"))}
                                </p>

                                <a href="/exclusive" className="btn effecto mt-4"> Read More </a>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="section_solution text-center">

                    <h2 className="section_title my-2">YOUR PREMIER SWISS TRAVEL SOLUTION</h2>

                    <h2 className="section_subtitle mb-md-5">Make Your Ride Smoother with VIP-Transfers in Switzerland</h2>

                    <a href="https://booking.vip-transfers.ch/" className="btn btn-style mt-2"> Book Now </a>

                </div>

                {/*<div className="row" id="video">
                    <iframe className="video" src="https://www.youtube.com/embed/KrIEWII4XK8?rel=0&playsinline=1&autoplay=1&loop=1&modestbranding=0&mute=1&playlist=KrIEWII4XK8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>*/}

                    {/* <Row className="material_row">
                        <div className="col-lg-12 newsletter_inputs">
                            <p className="subscribe-text">Subscribe now</p>
                            <Input
                                onChange={this.handleChangeName}
                                value={this.state.name}
                                className="required" id="name" name="name" ref={(c) => (this.name = c)}
                                label="Name *" validate required
                            ></Input>
                            <Input
                                onChange={this.handleChangeEmail}
                                value={this.state.email}
                                className="required" id="mail" name="mail" ref={(c) => (this.mail = c)}
                                label="Email *" validate required type="email"
                            ></Input>
                            <div className="input-field">
                                <button
                                    onClick={this.sendMailSubscribe}
                                    id="subscribe" className="btn btn-style btn-style-sm"
                                    type="submit" name="action"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className={"alert " + this.state.alertClass} style={style}>
                                {this.state.alertMessage}.
                            </div>
                        </div>
                    </Row> */}

            </div>
        );
    }
}

export default Home;