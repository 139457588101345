const ru = {
    pickupLocation: "Pickup location ru",
    destination: "Destination ru",
    date: "Date ru",
    home: "Home ru",
    signUpForOurNewsletter: "Sign up for our Newsletter ru",
    quickLinks: "Quick links ru",
    discoverUs: "Discover us ru",
    services: "Discover sadasdasd dfsafdf us ru",
    home_welcome_text:"текст на русском",
};
export default ru;