import { Component } from "react";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import images from "../locale/services";
import { I18n } from "react-i18nify";
import { Router, Route, Link } from "react-router-dom";
import { actionServices } from "../actions/index";

class HomeServicesMob extends Component {
    showItems() {
        const services = I18n.t("services");
        var lang = this.props.activeLang.code;
        return Object.keys(services).map((index) => {
            return (
                <div
                    className={"service-block-mob service-block-" + index}
                    key={"col" + index}
                >
                    <div
                        onClick={() => this.props.service(services[index])}
                        key={"card" + index}
                    >
                        <div key={"card-image" + index}>
                            <Link
                                to={{
                                    pathname:
                                        "/service" + "/" + services[index].title_url,
                                    state: { service: services[index], allervices: services },
                                }}
                            >
                                <img
                                    className="service-img-mob"
                                    src={"/images/services/service-block-img-"+index+".jpg"}
                                />

                                <div key={"card-content" + index} className="card-content">
                                    <h3 key={"h3" + index}>{services[index].title}</h3>
                                    <p key={"p" + index}>{services[index].desc}</p>
                                    <div className="btn btn-white mb-4"> Book Now </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return <div className="service_items_mob">{this.showItems()}</div>;
    }
}

function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ service: actionServices }, dispach);
}



export default connect(mapStateToProps, matchDispatchToProps)(HomeServicesMob);
