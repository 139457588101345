import { Component } from "react";
import React from "react";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import "../helper/js/babel.js";
// import "materialize-css/dist/js/materialize.js";
// import "materialize-css/dist/css/materialize.css";

import { I18n } from "react-i18nify";

import Select from "react-select";
import "react-select/dist/react-select.css";

import { getRoutesFrom } from "../actions/index";
import {
    fetchDataFrom,
    fetchDataTo,
    fetchMailBooking,
    fetchMailSubscribe,
} from "../services/RequestService";

import { Row, Section, Input, Icon, Textarea } from "react-materialize";

import $ from "jquery";

class Booking extends Component {
    constructor(props) {
        super(props);

        var today = new Date(),
            date =
                today.getDate() +
                "-" +
                ("0" + (today.getMonth() + 1)) +
                "-" +
                today.getFullYear();
        this.state = {
            alert: false,
            alertClass: "alert-danger",
            alertMessage: null,
            alertType: null,
            email: "",
            name: "",
            surname: "",
            message: "",
            date: date,
            time: "00:00",
            selectedOptionFrom: null,
            selectedOptionTo: null,
            options: null,
            routesTo: null,
            countChildren: 0,
            countAdults: 1,
        };

        this.setAdultsCountPlus = this.setAdultsCountPlus.bind(this);
        this.setAdultsCountMinus = this.setAdultsCountMinus.bind(this);
        this.setChildrenCountMinus = this.setChildrenCountMinus.bind(this);
        this.setChildrenCountPlus = this.setChildrenCountPlus.bind(this);
        this.sendBooking = this.sendBooking.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange = (date) => {
        this.setState({ date: date });
    };

    timeChange = (time) => {
        // this.setState({ time })
    };

    componentDidMount() {
        fetchDataFrom().then((result) => this.props.getRoutes(result));
    }

    setAdultsCountPlus() {
        let count = this.state.countAdults;
        count++;
        var time = $("#timePicker").val();
        var date = $("#date_picker").val();
        this.setState({
            countAdults: count,
            time: time,
            date: date,
        });
    }

    setAdultsCountMinus() {
        let count = this.state.countAdults;
        if (count >= 1) {
            count--;
            var time = $("#timePicker").val();
            var date = $("#date_picker").val();
            this.setState({
                countAdults: count,
                time: time,
                date: date,
            });
        }
    }

    setChildrenCountMinus() {
        let count = this.state.countChildren;
        if (count >= 1) {
            count--;
            this.setState({
                countChildren: count,
            });
        }
    }

    setChildrenCountPlus() {
        let count = this.state.countChildren;
        count++;
        var time = $("#timePicker").val();
        this.setState({
            countChildren: count,
            time: time,
        });
    }

    sendBooking(e) {
        e.preventDefault();

        var { surname, name, email, message } = this.state;

        var date = $("#date_picker").val();
        var time = $("#timePicker").val();

        if (this.state.selectedOptionFrom && this.state.selectedOptionTo) {
            var from = this.state.selectedOptionFrom.value;
            var to = this.state.selectedOptionTo.value;
        }

        var adults = this.state.countAdults;
        var children = this.state.countChildren;

        var error = false;

        $(".error").remove();

        if (adults < 1) {
            $(".adults_row").append(
                " <div className='error' style='width:100%;color: #ca0505'>You should at least select 1 adult passenger</div>"
            );
        }

        if (!this.state.selectedOptionFrom) {
            $(".from_select_parent").append(
                " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
            );
        }

        if (!this.state.selectedOptionTo) {
            $(".to_select_parent").append(
                " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
            );
        }

        if (!email) {
            $("#mail")
                .parent()
                .append(
                    " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
                );
        }

        if (email) {
            var emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) {
                $("#mail")
                    .parent()
                    .append(
                        " <div className='error' style='width:100%;color: #ca0505'>Please enter a valid email address</div>"
                    );
                error = true;
                return false;
            }
        }

        if (!name) {
            $("#name")
                .parent()
                .append(
                    " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
                );
        }
        if (!surname) {
            $("#surname")
                .parent()
                .append(
                    " <div className='error' style='width:100%;color: #ca0505'>This field is required</div>"
                );
        }

        if (
            email &&
            name &&
            surname &&
            date &&
            time &&
            from &&
            to &&
            adults > 0 &&
            !error
        ) {
            let data = {
                url: "sendMailVipBooking",
                email: email.replace(/\./g, "/"),
                date: date,
                name: name,
                surname: surname,
                time: time,
                from: from,
                to: to,
                adults: adults,
                children: children,
                message: message,
            };

            fetchMailBooking(data, time).then((result) => {
                if (result && result.message == "success") {
                    const successMessage =
                        "Thank you for your request, we confirm to receive it and we are going to answer you as soon as possible.";
                    var date = new Date(Date.now());
                    var day = date.getDate();
                    var month = date.getMonth();
                    var year = date.getFullYear();
                    date = day + "-" + month + 1 + "-" + year;
                    this.setState({
                        alert: true,
                        alertMessage: successMessage,
                        alertClass: "alert-success",
                        email: "",
                        name: "",
                        surname: "",
                        message: "",
                        date: date,
                        time: "00:00",
                        selectedOptionFrom: null,
                        selectedOptionTo: null,
                        countAdults: 1,
                        countChildren: 0,
                    });
                    setTimeout(function () {
                        window.location.href = "https://vip-transfers.ch/";
                    }, 5000);
                }
            });
        }
    }

    handleChangeFrom = (selectedOptionFrom) => {
        this.setState({ selectedOptionFrom });
        this.setState({ selectedOptionTo: null });
        var time = $("#timePicker").val();
        fetchDataTo(selectedOptionFrom).then((result) =>
            this.setState({
                routesTo: result,
                time: time,
            })
        );
    };

    handleChangeTo = (selectedOptionTo) => {
        var time = $("#timePicker").val();
        this.setState({
            selectedOptionTo: selectedOptionTo,
            time: time,
        });
        // console.log(`Option selected:`, selectedOptionTo);
    };
    handleChangeEmail = (event) => {
        var time = $("#timePicker").val();
        this.setState({
            email: event.target.value,
            time: time,
        });
    };

    handleChangeName = (event) => {
        var time = $("#timePicker").val();
        this.setState({
            name: event.target.value,
            time: time,
        });
    };

    handleChangeSurname = (event) => {
        var time = $("#timePicker").val();
        this.setState({
            surname: event.target.value,
            time: time,
        });
    };
    handleChangeMessage = (event) => {
        this.setState({
            message: event.target.value,
        });
    };

    render() {
        var points_from = this.props.optionsFrom;

        const { selectedOptionFrom } = this.state;
        const { selectedOptionTo } = this.state;
        const { routesTo } = this.state;

        var style = {
            display: "none",
        };

        var rowStyle = {
            display: "flex",
        };

        if (this.state.alert) {
            style = {
                display: "block",
            };

            rowStyle = {
                display: "none",
            };
        }

        return (
            <div>
                <div className="other-header"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-block">
                                <h1>Get a Qoute</h1>
                                <img className="mt-3 mb-5" src="/images/rotated-dekor.svg" />
                            </div>
                        </div>
                    </div>
                    <Section className="booking_section">
                        <form className="form-horizontal">
                            <div
                                className={"alert thank_you_booking " + this.state.alertClass}
                                style={style}
                            >
                                <p className="text-center">{this.state.alertMessage}.</p>
                            </div>
                            <Row style={rowStyle}>
                                <div className="col-lg-4">
                                    <img
                                        className="contact-img"
                                        src="/images/qoute-img.jpg"
                                        alt=""
                                    />
                                </div>
                                <div className="col-lg-7 p-5">
                                    <div className="row">
                                        <div className="col s12">
                                            <p className="form-sec-title">Transfer</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s6">
                                            <div className="from">
                                                <div className="from_select_parent">
                                                    <div className="flex-input">
                                                        <Select
                                                            placeholder={I18n.t("from")}
                                                            value={selectedOptionFrom}
                                                            onChange={this.handleChangeFrom}
                                                            options={points_from ? points_from.points : null}
                                                            name="from"
                                                            ref={(c) => (this.from = c)}
                                                            noResultsText={
                                                                <span>
                                  Not Found?{" "}
                                                                    <a
                                                                        style={{
                                                                            color: "#81755C",
                                                                            textDecoration: "underline",
                                                                        }}
                                                                        href={"/contact"}
                                                                    >
                                    Contact Us
                                  </a>
                                </span>
                                                            }
                                                            id="from_select"
                                                            className="from_select col-lg-12"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6">
                                            <div className="to">
                                                <div className="to_select_parent">
                                                    <div className="flex-input">
                                                        <Select
                                                            placeholder={I18n.t("to")}
                                                            value={selectedOptionTo}
                                                            onChange={this.handleChangeTo}
                                                            options={routesTo}
                                                            name="to"
                                                            id="to_select"
                                                            noResultsText={
                                                                <span>
                                  Not Found?{" "}
                                                                    <a
                                                                        style={{
                                                                            color: "#81755C",
                                                                            textDecoration: "underline",
                                                                        }}
                                                                        href={"/contact"}
                                                                    >
                                    Contact Us
                                  </a>
                                </span>
                                                            }
                                                            ref={(c) => (this.to = c)}
                                                            className="col-lg-12 required"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s6 flex-input mb-3">
                                            <div
                                                id="datepicker"
                                                className="input-group date"
                                                data-date-format="dd-mm-yyyy"
                                            >
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.date}
                                                    className="form-control"
                                                    type="text"
                                                    data-date-format="dd-mm-yyyy"
                                                    readOnly
                                                    name="startDate"
                                                    id="date_picker"
                                                />
                                            </div>
                                        </div>
                                        <div className="col s6 flex-input mb-3">
                                            <div className="input-group">
                                                <input
                                                    onChange={this.timeChange}
                                                    ref={(c) => (this.time = c)}
                                                    value={this.state.time}
                                                    id="timePicker"
                                                    name="time"
                                                    className="form-control required"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3 people_header_title">
                                        <div className="col s12">
                                            {I18n.t("passangers")}
                                            <span className="span_count_people">
                        {this.state.countAdults} Adults,{" "}
                                                {this.state.countChildren} Children
                      </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s6">
                                            <table className="count_peolple">
                                                <tbody className="count_peolple">
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div
                                                            className="btn-group"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <button
                                                                disabled={true}
                                                                type="button"
                                                                className="btn required"
                                                                id="aduls"
                                                                value={this.state.countAdults}
                                                            >
                                                                {this.state.countAdults}
                                                            </button>
                                                            <button
                                                                onClick={this.setAdultsCountMinus}
                                                                type="button"
                                                                className="minus btn btn-primary"
                                                            >
                                                                -
                                                            </button>
                                                            <button
                                                                onClick={this.setAdultsCountPlus}
                                                                type="button"
                                                                className="plus btn btn-primary"
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col s6">
                                            <table className="count_peolple">
                                                <tbody>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div
                                                            className="btn-group"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <button
                                                                disabled={true}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                id="children"
                                                            >
                                                                {this.state.countChildren}
                                                            </button>
                                                            <button
                                                                onClick={this.setChildrenCountMinus}
                                                                type="button"
                                                                className="minus btn btn-primary"
                                                            >
                                                                -
                                                            </button>
                                                            <button
                                                                onClick={this.setChildrenCountPlus}
                                                                type="button"
                                                                className="plus btn btn-primary"
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col s12">
                                            <p className="form-sec-title">Personal Details</p>
                                        </div>
                                    </div>
                                    <Row>
                                        <Input
                                            onChange={this.handleChangeName}
                                            value={this.state.name}
                                            type="text"
                                            ref={(c) => (this.name = c)}
                                            id="name"
                                            name="name"
                                            label="Name"
                                            s={6}
                                        />
                                        <Input
                                            onChange={this.handleChangeSurname}
                                            value={this.state.surname}
                                            type="text"
                                            ref={(c) => (this.surname = c)}
                                            id="surname"
                                            name="surname"
                                            label="Surname"
                                            s={6}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            onChange={this.handleChangeEmail}
                                            value={this.state.email}
                                            type="email"
                                            ref={(c) => (this.mail = c)}
                                            id="mail"
                                            name="mail"
                                            label="Email"
                                            s={12}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            onChange={this.handleChangeMessage}
                                            value={this.state.message}
                                            type="textarea"
                                            ref={(c) => (this.message = c)}
                                            id="message"
                                            name="message"
                                            label="Message"
                                            s={12}
                                        />
                                    </Row>
                                    <Row>
                                        <div className="col-lg-12">
                                            <button
                                                onClick={this.sendBooking}
                                                type="submit"
                                                className="btn btn-style btn-style-sm send_request"
                                            >
                                                Send Request
                                            </button>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </form>
                    </Section>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        optionsFrom: state.actions,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ getRoutes: getRoutesFrom }, dispach);
}

export default connect(mapStateToProps, matchDispatchToProps)(Booking);
