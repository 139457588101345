
import {combineReducers} from 'redux';
import LangReducer from './LangReducer';
import actions from './actions-reducer';


 const allReducers =combineReducers({
        LangReducer,
        actions,

 })

export  default allReducers;