// DOM Ready


import $ from "jquery";
import 'materialize-css/js/date_picker/picker.js';
import 'materialize-css/js/date_picker/picker.date.js';

import 'materialize-css/dist/js/materialize.js'

    $(document).ready(function(){

        $('.select2').select()
        var url      = window.location.href;
        var id_split=url.split('/');
        var id=id_split[id_split.length-1];
        var menu_array=['about','services','contact','fleet','faq']
        var bool=$.inArray(id,menu_array)
       /* if(bool > -1){
            let offset = $('#'+id).position();
            $('.underline').css('left',offset.left+15+'px');
        }*/
        

           /* $( ".service-block-0" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-0" ).css('opacity', '1')
            });

           $( ".service-block-1" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-1" ).css('opacity', '1')
            });

           $( ".service-block-2" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-2" ).css('opacity', '1')
            });

           $( ".service-block-3" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-3" ).css('opacity', '1')
            });

           $( ".service-block-4" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-4" ).css('opacity', '1')
            });

           $( ".service-block-5" ).on( "mouseover", function() {
              $(".service-block-img").css('opacity', '0')
              $(".service-block-img-5" ).css('opacity', '1')
            }); */


        $( ".panel-title a" ).on( "click", function() {
            if($(this).parents('.panel').children('.panel-collapse').hasClass('show')){
                $(this).parents('.panel').removeClass('clicked')
            }
            else {
                $(this).parents('.panel').addClass('clicked')
            }
        });


        if (window.location.href.indexOf("about") > -1) {
            $('#about').addClass('active')
        }
        else if (window.location.href.indexOf("services") > -1) {
            $('#services').addClass('active')
        }
        else if (window.location.href.indexOf("fleet") > -1 || window.location.href.indexOf("car") > -1) {
            $('#fleet').addClass('active')
        }
        else if (window.location.href.indexOf("contact") > -1) {
            $('#contact').addClass('active')
        }
        else if (window.location.pathname === '/en' || window.location.pathname === '/') {
            $('#home').addClass('active')
        }
    })



