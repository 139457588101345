import {Component} from "react";
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import {I18n} from "react-i18nify";
import HTMLrender from 'react-render-html';

class AboutUs extends Component {

/*             constructor(props){
                super(props)
                this.state = {
                    autoplay: true,
                };
                this.carGaleryImages=this.carGaleryImages.bind(this)
            }

            carGaleryImages(){

                let {car}=this.props
                  let actionType=car?car.type:null;
               if(actionType=='GET_ROUTES_FROM'){
                    car=I18n.t('cars')[0]
                   console.log(car,'function')
                }

                if(car != null){
                    return  Object.keys(car.galeyiImg).map((index) => {
                        return   <img key={'img'+index} src={car.galeyiImg[index].url}/>
                    })
                }
            } */

    render () {

            return (

                <div>
                    <div className="section_1">
                        <div className="header-img about_page">
                            <h1>Swiss Quality <br></br>Private Transportation Services</h1>
                            <a href="https://booking.vip-transfers.ch/" className="btn btn-style fish mt-4"> Book Now </a>
                        </div>
                    </div>

                    <div className='container pb-4'>
                        <h2 className="section_title my-md-5 my-3">ABOUT US</h2>
                        <p className="home_p">
                        VIP-Transfers, a luxury transportation company based in Fribourg, Switzerland, is a distinguished branch of ARATOURS SA, a seasoned player in the travel and transfer industry. With over 15 years of experience, our company specializes in private airport transfers, premium car at disposal services, and point-to-point private transfers throughout Switzerland, all facilitated by our VIP fleet.</p> 
                        <p className="home_p">As an ever-evolving company, VIP-Transfers excels in providing diverse service packages. Our high reputation is built upon around-the-clock premium-class transportation services, earning the trust and satisfaction of our customers. We provide top-notch service at competitive rates, making us the preferred choice for travelers who value luxury and reliability.</p>
                    </div>
    
                    <div className="container-fluid values_section">
                        <div className="container w-100-mob pb-5">
                            <h2 className="section_title text-center mb-3">Our Values</h2>
                            <img src="/images/values.png" className="d-lg-none d-block"></img>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src="/images/services/cardisposal.svg"></img>
                                    <h3 className="section_subtitle py-2">Exclusive VIP Fleet</h3>
                                    <p className="home_p">By owning a huge variety of exclusive VIP fleet, we assure our valuable customers the experience of a safe, smooth and enjoyable ride. Our first class sedans, minivans, minibuses, electric and hybrid cars are at your disposal to travel in Switzerland.</p>
    
                                    <img className="mt-4" src="/images/services/driver.svg"></img>
                                    <h3 className="section_subtitle py-2">Expert  Drivers</h3>
                                    <p className="home_p">All our services are accompanied by professional drivers, who are not only knowledgeable about every single corner of Switzerland, but also exhibit language proficiency and friendliness to enhance your travel experience.</p>
    
                                    <img className="mt-4" src="/images/services/team.svg"></img>
                                    <h3 className="section_subtitle py-2">Skilled Operation Team</h3>
                                    <p className="home_p">The entire team at VIP-Transfers is dedicated to providing suggestions, organizing, and seamlessly executing private transfers, ensuring you uncover the beauty of this unique corner of the world—Switzerland.</p>
    
                                </div>
    
                                <div className="col-lg-6 align-self-lg-end">
                                    <img className="mt-4" src="/images/services/safe.svg"></img>
                                    <h3 className="section_subtitle py-2">Clients Safety</h3>
                                    <p className="home_p">Ensuring client safety is our top priority at VIP-Transfers, and our skilled team is committed to providing secure and reliable private transfers to you with safe vehicles. </p>
                                    {/* <p className="home_p">The success of our business is linked to the excellence of our VIP fleet, a highly qualified and dedicated team, a range of exclusive services, a reliable rate policy, and a continuously growing number of satisfied customers.</p> */}
                                
                                   
                                </div>
                            </div>
                        </div>
                    </div>

                            
                    <div className='block-black py-4'>
                        <div className='container pb-5'>
                            <h2 className="section_title text-center mb-3">Mission Statement</h2>
                                <p className="home_p">At VIP-Transfers, our primary mission is to build long-term relationships with our travelers while delivering impeccable customer services through innovative and advanced technology. </p>
                                    <p className="home_p">We are dedicated to meeting the private transfer needs and expectations of passengers arriving in Switzerland and nearby regions, making a distinctive mark in the Swiss market with point-to-point transportations.</p>
                                    <p className="home_p">VIP-Transfers is your valuable solution, so request your online quote now for our luxurious Mercedes Benz Classes, Sprinters, and Teslas, and enjoy the best prices and exceptional service.</p>
                            
                            <div className="row mt-5" id="video">
                                <iframe className="video" src="https://www.youtube.com/embed/KrIEWII4XK8?rel=0&playsinline=1&autoplay=1&loop=1&modestbranding=0&mute=1&playlist=KrIEWII4XK8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            )
        

    }
}

export default AboutUs;