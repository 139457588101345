import {
   getRoutesFrom
} from "../actions/index";
import $ from "jquery";
const config = {
    requestPath : 'https://api.alptransfer.com/api/'
};
const headers = {
    'Accept': 'application/json, text/javascript, */*; q=0.01',
    'Accept-Encoding': 'gzip, deflate',
    'Accept-Language': 'en-US,en;q=0.9',
    'Connection': 'keep-alive',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
}
export async  function fetchDataFrom (data) {

    data = data ? data : {
        method : "GET",
        url : ''
    };

    const headers = {
        'Accept': 'application/json, text/javascript, */*; q=0.01',
        'Accept-Encoding': 'gzip, deflate',
        'Accept-Language': 'en-US,en;q=0.9',
        'Connection': 'keep-alive',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }
    const url='points';

    try {
        const response = await fetch(config.requestPath+url, {
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return   json.map((point,index)=>{
            return  {
                label:point.title,
                value:point.id
            }
        })
    } catch (error) {
        console.log(error);
    }


}




export async  function fetchDataTo (data) {

    data = data ? data : {
        method : "GET",
        url : ''
    };
        const url='points';


    try {
        const response = await fetch(config.requestPath+url+'/'+ data.value , {
            headers
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return   json.map((point,index)=>{
            return  {
                label:point.title,
                value:point.id
            }
        })
    } catch (error) {
        console.log(error);
    }


}




export async  function fetchMailNewsLetter (data) {

    data = data ? data : {
        method : "POST",
        url : 'sendMailNewsLetter'

    };

   let dataJson=JSON.stringify(data)

    try {
        const response = await fetch(config.requestPath+ data.url, {

            method: "post",
            body:dataJson,
            type:'json',
            headers: {'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',}
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const json = await response.json();

        console.log(json)
        return json;
    } catch (error) {
        console.log(error);
    }


}
export async  function fetchMailContact (data) {

    data = data ? data : {
        method : "POST",
        url : 'sendMailContact'
    };
        let dataJson=JSON.stringify(data)

    try {
            const response = await fetch(config.requestPath+ data.url, {

                method: "post",
                body:dataJson,
                type:'json',
                headers: {'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',}
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            return json;
        } catch (error) {
            console.log(error);
        }

}



export async  function fetchMailBooking (data,time) {

    let dataJson=JSON.stringify(data,time)


    try {
        const response = await fetch(config.requestPath+ data.url,{

            method: "post",
            body:dataJson,
            type:'json',
            headers: {'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'},
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();

       return json;
    } catch (error) {
        console.log(error);
    }

}






