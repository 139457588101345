import { Component } from "react";
import React from "react";
import CarItem from "../containers/cars-item";

class Covid extends Component {
    constructor() {
        super();
    }

    render() {
        window.scrollTo(0, 0);

        return (
            <div>
                <div className="other-header">
                    <a href="https://booking.vip-transfers.ch/" className="btn btn-style mt-5">
                        Book Now
                    </a>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-block">
                                <h1>Our Safety Precautions for CoVid -19</h1>
                                <img className="mt-3 mb-5" src="/images/rotated-dekor.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 covid-block pb-5">
                            <p>
                                The world Pandemic has affected everyone around the globe, in
                                its turn, our company remains responsible to assure its main
                                duty - your safe and comfortable trip. Our goal is the provision
                                of a safe environment, where passengers are transported in
                                complete peace of mind, something they would not necessarily
                                have in other means of transportation or in public transport. We
                                do our utmost to guarantee our passengers the highest quality of
                                disinfection on board of all our vehicles. Loyal to our main
                                goal: safe transfers of our passengers, we have developed and
                                implemented respective safety regulations and practices in
                                cooperation with health experts.
                                <br />
                                <br />
                                In the condition of Covid-19 Pandemic, it is more important than
                                usual to maintain regular cleaning activities of our vehicles to
                                avoid any contamination. We find it important to mention that
                                all our drivers are constantly monitoring the latest news on
                                Covid-19 and are ready to provide you with important information
                                during the transfer. In order to reach a maximum level of
                                safety, we have designated a disinfection service to protect the
                                health of our passengers and drivers. The disinfection uses
                                specially selected products recommended by experts in public
                                health, which guarantees high quality and efficiency of the
                                process.
                                <br />
                                <br />
                                Our team of drivers is under close constant supervision and is
                                provided with protective equipment, their temperature is
                                regularly checked. After each transfer, and before starting a
                                new transfer all cars are strictly and completely disinfected by
                                a certified company.
                                <br />
                                <br />
                                It should be noted that the wearing of protective face masks by
                                passengers is mandatory during the entire journey. A hand
                                disinfectant is available in each vehicle and must be used by
                                the clients before boarding the car.
                                <br />
                                <br />
                                We are grateful for your continued trust and willingness to
                                comply with these basic sanitary and hygienic procedures to
                                protect your and our health.
                                <br />
                                <br />
                                Enjoy clean and comfortable transfers and entrust your safety to
                                our team. Vip-transfers.ch does its best to preserve your
                                health, guarantee your safety and ensure a pleasant VIP
                                transfer.
                                <br />
                                <br />
                                Here is a summary of the health and hygiene measures taken by
                                our company:
                                <br />
                            </p>
                            <ul>
                                <li>- The vehicle is disinfected before each transfer. </li>
                                <li>- Driver wears a protective face mask </li>
                                <li>
                                    - The driver disinfects his hands regularly between trips.
                                </li>
                                <li>
                                    - The driver ensures that fresh air is constantly circulating
                                    in the vehicle.
                                </li>
                                <li>
                                    - Measurement and control of the driver's temperature every
                                    morning before the start of work
                                </li>
                                <li>
                                    - Availability of masks and hand sanitizers for passengers.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Covid;