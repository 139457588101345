import { Component } from "react";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { I18n } from "react-i18nify";
import { Link } from "react-router-dom";
import { actionCars } from "../actions/index";
import { Row } from "react-materialize";

class CarsList extends Component {

    
    carFeatures(cf){
        const  features_array=  I18n.t("features");

        if (cf != null) {
            return Object.keys(cf).map((index) => {
                const featureName = cf[index];
                 const featureArray = Object.values(features_array);
                const matchingFeature = featureArray.find((feature) => feature.name === featureName);
                const featureText = matchingFeature ? matchingFeature.text : featureName;
                const featureIcon = matchingFeature ? matchingFeature.name : 'noIcon';

                return <div key={'f' + index} className="car_feature">                    
                    <img key={'img'+index} src={"/images/cars/features/"+featureIcon+".svg"}/>
                    <span className="car_feature_text">{featureText}</span>
                </div>;
            });
        }
    }

    showItems() {
        const cars = I18n.t("cars");

        const lang = this.props.activeLang.code;
        return Object.keys(cars).map((index) => {
            const car = cars[index];
            return (
                <div className="col-xl-4 col-md-6  mt-4" key={"col" + index}>
                    <div className="cars_home" key={"card" + index} onClick={() => this.props.car(car)} >
                        <Link
                            to={{ pathname: "/car/" + car.title_url }} target="_blank"
                        >
                            <div className="fleet_card">
                                <div key={"action" + index}>
                                    <p className="card_title" key={"p" + index}>{car.title}</p>
                                    <p className={car.type+" card_type"}>{car.type}</p>
                                </div>
                                
                                <div key={"card_img" + index} className="text-center">
                                    <img className="card_img" 
                                        key={"img" + index}
                                        src={car.img}
                                    />
                                </div>
                                
                                <div className="car_options d-flex justify-content-between">
                                    <div className="car_option">
                                        <img src="/images/cars/icons/pax_icon.svg" />
                                        <span >{car.pax}</span>
                                    </div>
                                    <div className="car_option">
                                        <img src="/images/cars/icons/lug_icon.svg" />
                                        <span >{car.lug}</span>
                                    </div>
                                    <div className="car_option">
                                        <img src="/images/cars/icons/big_lug.svg" />
                                        <span >{car.lug}</span>
                                    </div>
                                </div>

                                <img className="seats" src={`/images/cars/seats/`+(car.id==17?'11exc':car.pax)+`.png`} />
                                <hr> 
                                </hr>

                                <div className="car_features">
                                    { this.carFeatures(car.features) } 
                                </div>
                                
                                <a href="https://booking.vip-transfers.ch/" className="btn btn-dark book">Book</a>

                            </div>
                        </Link>
                    </div>
                </div>
            );
        });
    }

    render() {
        return <Row>{this.showItems()}</Row>;
    }
}

function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ car: actionCars }, dispach);
}

export default connect(mapStateToProps, matchDispatchToProps)(CarsList);