import React, { Component } from "react";
import logo from "../logo.svg";
import backgroundImage from "../helper/images_logo/background.svg";
import "../App.css";
import { connect } from "react-redux";

import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";
import { I18n } from "react-i18nify";

class App extends Component {

      constructor(props) {
        super(props);
        this.state = {
            headerClass: 'header_light',
        };
    }
    componentDidMount() {
        document.body.classList.add('loaded');
    }
    
     updateHeaderClass = (newheaderClass) => {
        this.setState({ headerClass: newheaderClass });
    };
    
    render() {
        const divStyle = {};
        return (
            <div className="App">
                <div style={divStyle}>
                    <Header headerClass={this.state.headerClass} />
                    <Body updateHeaderClass={this.updateHeaderClass} />
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.activeLang,
});

export default connect(mapStateToProps)(App);