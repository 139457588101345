import { Component } from "react";
import React from "react";

class Privacy extends Component {
	render() {
		return (
			<div>
				<div className="header-img contact_page">
					<h1>Privacy Policy</h1>
                </div>
				<div className="container">
					<div className="terms_p py-5">
						Dear Valuable Customer, <br />
						We understand that making online financial transactions
						nowadays is rather risky and claims a great deal of trust
						towards the company. VIP-Transfers is committed to protecting
						your privacy by making the security of our clients’
						information one of its most important priorities.
						
						<h5>Information Provision</h5>
						VIP-Transfers collects a personal information about the
						traveller, which is obligatory in order to ensure successful
						fulfilment of the required private transfer reservation. For
						making a booking, we will need lead passenger name, the exact
						number of the passengers, phone number, e-mail address,
						payment by one of those choices: (Credit Card, Paypal, Bank
						transfer), flight number or pickup time and accommodation
						details.
						
						<h5>Who gets the information?</h5>
						The information gets ARATOURS GmbH: an umbrella company for
						VIP-Transfers when you are making the booking request.
						
						<h5>Personal Information Sharing</h5>
						VIP-Transfers does not sell or pass your personal information
						to any third party.
						
						<h5>Information Updates</h5>
						If you need to make any additional updates to already provided
						information, please, send us an e-mail at
						info@vip-transfers.ch or call by our customer support number
						+41 58 580 0774.
						
						<h5>Financial Transaction Security</h5>
						For online payments, we use Concardis pay engine, which is
						secured by https communications protocol. This means that
						while sending the information from your computer/phone to our
						servers, the site uses encryption, and the entered Credit Card
						details remain safe.
						
						<h5>Responses to Quotes / Questions / Suggestions</h5>
						For answering to any received issues or questions,
						VIP-Transfers uses the e-mail address that is provided by your
						side. Therefore, please make sure that your personal e-mail
						address is correctly worded.
						
						<h5>E-mails concerning News and Marketing</h5>
						When you subscribe to our website, we keep regularly sending
						you news regarding our company’s offers and discounted
						transfers. If you do not want to receive any of these e-mails,
						you can unsubscribe at any moment you wish.
						
						<h5>Other websites</h5>
						When you click the links presented in our partners’ page, the
						following privacy policies cannot be applied anymore. We
						advise reading the privacy policy of the visited site before
						using or sharing your personal information. This point applies
						if we will have a partner page.
						
						<h5>Compliance</h5>
						We regularly review the company’s compliance with this privacy
						policy. We highly appreciate any feedback, as it will assist
						us in becoming better at what we do. We are taking every
						action possible to make sure that this problem is rectified
						and we are able to provide the best solution in case of caused
						inconveniences. The staff follows up any complaint received
						from the customer, until the issue is settled down and we have
						a satisfied client.
						
						<h5>Changes</h5>
						Our Privacy Policy may undertake certain changes from time to
						time. All the changes will be available on this page for your
						observation.
						
						<h5>Terms and Conditions</h5>
						You can change the details upon 24 hours in advance
						notification. The last-minute changes are subject to
						agreement.
						
						<h5>Do you provide handicapped service?</h5>
						Please, also visit our{" "}
						<a href="/terms">Terms and Conditions</a> section
						establishing the use, disclaimers, and limitations of
						liability governing the use of our website.
						
						<h5>Consent</h5>
						When you decide to use the services provided by VIP-Transfers,
						you consent to all the points introduced above.
					</div>
				</div>
			</div>
		);
	}
}

export default Privacy;