import { Component } from "react";
import React from "react";

import { Router, Route, Switch } from 'react-router-dom'; 
import history from "../history";
import Home from "./Home";
import About from "./AboutUs";
import Exclusive from "./Exclusive";
import Services from "./Services";
import Terms from "./Terms";
import Booking from "./Booking";
import Fleet from "./Fleet";
import Faq from "./Faq";
import NotFound from "./NotFound";
import Privacy from "./Privacy";
import Contact from "./Contact";
import Service from "./Service";
import Car from "./Car";
import Covid from "./Covid";
import "bootstrap/dist/css/bootstrap.min.css";

class Body extends Component {
  render() {
      
        return (
          <div className="body_parent_div">
              <Router history={history}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/about" component={About} />                  
                 
                  <Route exact path="/services"
                    render={(props) => ( <Services {...props} updateHeaderClass={this.props.updateHeaderClass} /> )} 
                    />
                 
                  <Route exact path="/service/:service" 
                    render={(props) => ( <Service {...props} updateHeaderClass={this.props.updateHeaderClass} /> )} 
                    />
                  
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/fleet" component={Fleet} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/exclusive" component={Exclusive} />
                  <Route exact path="/car/:car" component={Car} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/booking" component={Booking} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/covid" component={Covid} />
                  {/* The below Route will only be reached if no other routes match */}
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </div>
        );
    }
}

export default Body;