let initialData = {
    lang: [],
    activeLang: {
        name: "English",
        code: "en",
        icon: "gb-eng"
    }
};


const Actions = (state =  null, action) => {


    switch (action.type) {
        case 'CHANGE_CONTENT_SERVICE':
            return action.payload

        case 'CHANGE_CONTENT_CAR':
            return {
                points: action.payload,
                type:action.type
            }

         case 'GET_ROUTES_FROM':
            return {
                points: action.payload,
                type:action.type
            }
        default :
            return state;
    }
};
export default Actions;