import { Component } from "react";
import React from "react";
import HTMLrender from "react-render-html";

import { Router, Route, Link, Redirect } from "react-router-dom";
import { I18n } from "react-i18nify";
import connect from "react-redux/es/connect/connect";
import { actionServices } from "../actions/index";
import { bindActionCreators } from "redux";
import HomeServices from "../containers/home-services";

class Service extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.updateHeaderClass('header_dark');
    }
    

    render() {
        window.scrollTo(0, 300);

        let type = this.props.activeService ? this.props.activeService.type : null;
        let service = null;
        if (type && type == "GET_ROUTES_FROM") {
            service = {
                content: I18n.t("services")[0],
            };
        } else if (this.props.activeService && !type) {
            service = {
                content: this.props.activeService,
            };
        } else {
            service = {
                content: I18n.t("services")[0],
            };
        }

        let top_style = {backgroundImage: "url(/images/services/" + service.content.title_url + ".png)"};

        return (
            <div>
                <div className="container">
    
                    <div className="services_page_top row" style={top_style}>
                        <div className="col-6 d-none d-md-block">
                            <h1 className="section_title">{service.content.title}</h1>
                        </div>
                    </div> 

                    <div className="block-white">
                        <div className="d-md-none d-block">
                            <h1 className="section_title my-4 text-center">{service.content.title}</h1>
                        </div>
                        <p className="home_p">
                            {HTMLrender(service.content.text_top)}
                        </p>
                        <a href="https://booking.vip-transfers.ch/" className="btn effecto  fish white_fish mt-4"> Book Now </a>
                    </div>
                </div>

                <div className="block-black py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <img
                                    className="img-shadow mb-4"
                                    src={service.content.serviceImgBottom}
                                />
                            </div>

                            <div className="col-lg-6">
                                <p className="home_p">
                                    {" "}
                                    {HTMLrender(service.content.text_bottom)}
                                </p>
                            </div>
                        </div>
                    
                        <p className="home_p">
                            {" "}
                            {HTMLrender(service.content.text_big)}
                        </p>
                    </div>
                </div>

                <div className="space"></div>
            
                <div className="container pb-5">
                    <HomeServices  text="text" col="col-lg-4" />
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
        activeService: state.actions,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ service: actionServices }, dispach);
}

export default connect(mapStateToProps, matchDispatchToProps)(Service);