import { Component } from "react";
import React from "react";

class Terms extends Component {
	render() {
		return (
			<div>
				<div className="header-img contact_page">
					<h1>Terms & Conditions</h1>
                </div>
                
				<div className="container">
					<div className="content">
						<div className="terms_p py-3 py-md-5">
							<h5>Information</h5>
							ARATOURS SA trading as VIP-Transfers (hereinafter called the
							“Company”) represents below Terms and Conditions. By placing a
							booking by phone or online, you affirm, that you have
							considered and accepted our Company’s Terms and Conditions.
							The company shall not be liable for any errors or omissions
							that may happen, if you ignore being acknowledged with it. If
							you will have a question, a concern or need an explanation,
							our team is always at your disposal with assistance. The
							Company may alter Terms and Conditions from time to time.
							Therefore, you should check the new version of it on our
							website www.vip-transfers.ch regularly. The Company shall
							endeavor to act in good will and make all efforts within its
							competence to ensure an enjoyable and safe journey, which
							fully meets clients’ expectations.
							
							<h5>Online Booking Procedure</h5>
							While making a booking with us you are obliged to supply with
							all the necessary information, such as passenger name, dates,
							pick up information and payment details as well as ensure
							their credibility. If there will be any changes in previously
							provided transfer details, it will be your responsibility to
							contact us and request modifications. All notifications we
							send require an acknowledgment of receipt: you should check
							the voucher and inform us in case of any inconsistencies as
							soon as you receive it. Once we receive your transfer request,
							we will acknowledge it in written form and contact you
							regarding the payment.
							
							<h5>Transfer Price</h5>
							We offer you an all-inclusive price without any hidden costs,
							unless you may have any additional requests (additional drop
							off, waiting time, last minute updates etc.). The price shall
							be quoted between the Company and the Client based on current
							rates and tariffs. The prices quoted to the Client at the time
							of the reservation may subsequently be altered only if certain
							price determinants such as the Swiss franc/Euro exchange rate,
							fuel costs, taxes or other government levies change.
							
							<h5>Transfer Time Estimation</h5>
							Trip distance and time provided in the voucher are only for
							planning purposes: depending on traffic, weather and road
							conditions or any other event, distance and time of the
							transfer may be changed.
							
							<h5>Payment</h5>
							{/* The company accepts all widely used payment methods such as
							Credit Card, Bank Transfer (at least 5 days prior to the
							pickup date) and PayPal. In providing payment details, you
							warrant that you have authority to use the card and authorize
							us to withdraw the amount. Regarding Credit Card payments, for
							security reasons, we are eager to send you a secure payment
							link, so that you will not have to provide your CC details
							online. In case of cash payment bookings, the company requires
							Credit Card details only to secure your booking. The company
							will be authorized to withdraw the amount from the card, if
							you fail to use the transfer and do not acknowledge us about
							it. */}
							The Company accepts all widely used payment methods, such as Credit Cards and bank transfers (at least 5 days prior to the pick-up date). In the case of Credit Card payment, the Company will issue a secure online payment link visible only to the Client to process an online payment and to ensure the privacy of the Client’s personal data in full compliance with the relevant Data Privacy laws.
							

							<h5>Booking Changes</h5>
							{/* Changes to your reservation must be submitted via e-mail,
							phone or fax checked by the transfer operator and confirmed by
							e-mail. <br />
							You are liable for any increased transfer service cost
							resulting from requested change. Below are certain examples in
							case of which price alterations may be applied: <br />
							• Extra charge for the last-minute changes (changes before 48
							hours of the pickup time are acceptable) <br />
							• Additional drop-off/pick-up: 20 CHF is applicable from 1-4
							pax; 40 CHF is applicable from 5-8 pax; 60 CHF is applicable
							from 9-16 pax. <br />
							• 1 hour waiting time: 60 CHF is applicable from 1-4 pax; 90
							CHF is applicable from 5-8 pax; 120 CHF is applicable from
							9-16 pax. <br />
							• Change in actual number of passengers not reported earlier
							to transfer operator. <br />
							• Special rates shall apply to any requested services, which
							are not stated above. <br />
							• Any 3 changes in already confirmed booking are free of
							charge. Starting from the fourth modification 40 CHF
							bank fee is applicable. <br />
							All amendments are subject to availability. Amendments or
							cancellation requests should not be made with the drivers:
							only transfer operator is entitled to confirm your changes. */}


							Changes to the bookings must be submitted by e-mail, phone or fax, verified by the Operations team and acknowledged by e-mail. <br />
							
							The Client shall bear all modification costs resulting from requested booking changes or new additional services. Some examples are enumerated below:
							<ul>
								
								<li>• Additional stop, drop-off/pick-up: Please contact us for the exact additional rate.</li>
								
								<li>• For each extra hour of waiting time: Please get in touch with us for the exact additional rate.</li>
								
								<li>• Change in the actual number of Passengers that was not indicated by the Client and acknowledged by the Company in due time and form.</li>
								
								<li>• Special rates shall apply to any requested service which is not stated above.</li>
								
								<li>• The Client can request up to three changes in already confirmed booking free of extra charge. For the fourth change: Please get in touch with us for the exact additional rate.</li>
								
								<li>• Extra charges may be requested by the Company from the Client for last-minute booking changes. Changes requested by the Client 48 hours prior to the pickup time are generally accepted without such extra charges. The Company reserves the right to define the amount of such extra charges on a case-by-case basis.
								</li>
							</ul>
							
							All booking changes are subject to the Company’s availability of appropriate capacities. The client should not negotiate booking changes or cancellation requests with the drivers: only the Operations Team is entitled to process and acknowledge the booking changes requested by the client.
					
							<h5>Cancellation</h5>
							Free cancellation policy is applied to groups of 1-8 Passengers if the cancellation request is made 48 hours prior to the pickup time while charging the Client the bank fee of CHF 25.-. The company reserves the right to cancel a booking without a refund if the cancellation is requested less than 48 hours before the pickup time. <br/> Booking cancellations for large groups (more than 9 Passengers) are free only if the cancellation is requested 72 hours before the pick-up time while charging the Client the bank fee of CHF 25.-. The company reserves the right to cancel a booking without a refund if the cancellation is requested less than 72 hours before the pickup time. <br/> During special events and the hosting of International events, any cancellation for a group transfer reservation must be made at least 30 days before the pick-up date. <br/> The cancellation policy does not apply to “Non-Refundable” bookings. <br/> Any cancelation/amendments are not accepted for “Non-Refundable” bookings. <br/> Any cancellations requested over the phone or e-mail are effective only once acknowledged by the Operations Team by e-mail. <br/> The Company can provide an invoice for all canceled “Non-Refundable” bookings so the Client can use it for claims addressed to the Tour Operator, Airline, or Travel Insurance Provider.

							<h5>Last Minute Bookings</h5>
							Depending on the routes and the season, we can accept bookings
							from 0 to 48 hours before the pickup time. However, you are
							encouraged to make a booking 48 hours prior to the pickup
							time, otherwise certain changes may apply as a last-minute
							booking or we simply may have no availability.
							
							<h5>Transfer Changes and Cancellations by the Company</h5>
							In case of force majeure situations, the Company reserves the
							right to cancel the transfer or make changes to the
							transfer/travel arrangements. These include war, epidemics,
							natural disasters, military operations, etc., the effects and
							consequences of which cannot be reasonably mitigated or
							prevented, as well as delays and deviations caused by weather
							conditions (heavy rains, thunderstorms, fires, etc.) or public
							services (changes in the schedule of air transport and
							railway). In rare cases, if we cancel the booking, the Company
							shall notify the Client at the earliest possible date and
							refund all the amounts that have been prepaid to us.
							
							<h5>Child Pricing and Seats</h5>
							All children and infants regardless of age count should be
							included in the total number of passengers while making a
							booking. Child seats are being provided free of charge. You
							are encouraged to inform us about the age of travelling
							children, so that the company can provide a proper child seat.
							If you want to use your own child seat, we need to be informed
							about it to ensure that the vehicle provided can accommodate
							the seat.
							
							<h5>Luggage</h5> <br />
							Customers are limited to two items of luggage (one usual
							holiday luggage bag and a small hand luggage), and a ski or a
							snowboard bag per person. Any extra excess luggage must be
							submitted and informed in advance enabling the Company to
							accommodate with the suitable vehicle. Below you can be
							acquainted with luggage dimensions: <br />
							• Small_ 55 x 40 x 23 <br />
							• Medium_ 70 x 45 x 25 <br />
							• Large_ 80 x 50 x 30
							
							<h5>Delayed / Cancelled Flights</h5>
							The Company will monitor your flight, and in case your flight
							is delayed, the pickup time automatically will be adjusted to
							flight arrival time. However, if the flight is delayed more
							than an hour, the Company is entitled to release the driver
							and put your reservation on hold for the next available
							transfer at the earliest possible time. Whenever the flight is
							cancelled, then the Company is entitled to release the driver
							and claim a payment from the Client. We will provide you with
							an invoice, so that you can claim the reimbursement from the
							Air Company or travel insurance company.
							
							<h5>Contact procedure / Waiting Time / No-show transfers</h5>
							Below the voucher, you will find the Company hotline number:
							we strongly advise to call our hotline in case of any delays
							or cancellations and we will advise you on further procedure
							with the booking. <br />
							Free waiting time at the airports is up to 1 hour. After an
							hour, the driver can be released or as per availability wait
							for the client for extra payment. In case of non-airport
							pickups, the free waiting time is 15 minutes. <br />
							It is your responsibility to call the Company if you are not
							able to locate the driver at earlier agreed upon pickup place,
							or if you are delayed in immigration, customs, lost luggage or
							baggage collection. If the customer fails to call the company
							and inform about any kind of delays, then after free waiting
							time as per pickup locations mentioned above (airport 1 hour
							and non-airport locations 15 minutes) the service will be
							considered a “no-show”. In case of “no-show” customer accepts
							to pay full cost of the booked service.
							
							<h5>Vehicle</h5>
							The images illustrated on the quote page are for illustration
							purposes only. You can have a look at the Company’s fleet,
							which is represented, but not limited in the “Fleet” page of
							our website. Therefore, we do not guarantee that these exact
							vehicles will be provided. For 13+ passenger transfers, the
							Company is entitled to use 2 vehicles if there is no
							availability for a one with the provided transfer details. The
							company may also provide a larger vehicle for certain
							transfers, if there is no availability for a small one.
							
							<h5>Our Liability</h5>
							If the Company fails to comply with these Terms and
							Conditions, then it is responsible only for loss or damage
							caused to you because of the breach and negligence. Hence, a
							refund will be made as per the caused damage by the company.
							We are not liable for accidents, which occur during the
							provision of the service such as illnesses, injuries or death,
							unless it is caused by our negligence. <br />
							The Company has no responsibility for the eventuality of
							missed flights or connections due to bad weather conditions,
							traffic congestion, road accidents, acts of terrorism and
							vandalism. If the Company fails for any reason within our
							control to deliver its passengers to their confirmed
							destination, ARATOURS SA shall provide suitable transport such
							as another coach, train, private car, taxi etc. Any
							reimbursement made by the Company for the costs of alternative
							means of transport incurred by the passenger to get to their
							ticketed destination shall not exceed the cost of getting to
							that destination by a taxi. <br />
							In case of extremely adverse weather conditions, the Company
							undertakes to explore other transport options for customers.
							This may happen when there is a substantial risk for the
							customer and driver involved in attempting the journey and
							this decision shall rest solely with the management of
							ARATOURS SA.
							
							<h5>Your Responsibility</h5>
							The client is solely responsible for the accuracy of the
							provided transfer details. Such as exact pickup/drop off
							address, available phone number during the travel, the age of
							travelling children, number of pieces of luggage and their
							size. If they do not correspond to the measurements provided
							in the luggage section of these Terms and Conditions, then you
							need to inform us about it. <br />
							Parents or chaperons (or friends over 18 years) of children
							and adolescents under the age of 18 years are responsible for
							the conduct of the minors while in the Company’s vehicles and
							shall take responsibility for any damage caused by the
							aforementioned minors. <br />
							All passengers in all vehicles must fasten their seat belts.
							In case of an accident, the Company’s liability is limited to
							the conditions of its insurance contract. Smoking and
							consumption of alcohol/spirits are strictly prohibited in the
							Company’s vehicles. It is your responsibility to provide full
							and correct information while making a booking and notify the
							Company in case of any alterations in the details, that you
							have previously provided. <br />
							Hereby you authorize us that the Company is entitled to charge
							the card for any damage incurred during the transfer by your
							fault.
							
							<h5>Force Majeure</h5>
							The Company shall not be liable for any delays, changes or
							service cancellations due to force majeure, or to other
							circumstances that are unforeseeable or beyond the service
							provider control, such as accidents suffered by the third
							parties on the transfer route, police checkpoints, acts of
							terrorism, extreme weather conditions etc.
							
							<h5>Disputes and Limitation of Damages</h5>
							In the event of a dispute, the Company and the Client shall
							endeavor to seek an amicable and faithful agreement. If the
							parties fail to reach such an agreement, the dispute shall be
							referred to a mediator. However, if the mediation is also
							unsuccessful, the case of dispute shall be referred to a
							formal litigation process in the courts of Fribourg,
							Switzerland.
							
							<h5>Complaints</h5>
							If the service quality does not meet your expectations, you
							are strongly encouraged to inform us about it preferably in
							written form by sending us an e-mail or completing the special
							form in the contact page.
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Terms;