import { Component } from "react";
import React from "react";

import contactImg from "../helper/images_logo/contact.svg";
import ratesImg from "../helper/images_logo/rates.svg";
import discoverImg from "../helper/images_logo/discover.svg";

import { Router, Route } from "react-router-dom";

class Footer extends Component {
    render() {
/*         const contact_icon = {
            width: "30px",
            height: "30px",
            float: "left",
            marginRight: "10px",
            backgroundImage: "url(" + contactImg + ")",
        };

        const rates_icon = {
            width: "30px",
            height: "30px",
            float: "left",
            marginRight: "10px",
            backgroundImage: "url(" + ratesImg + ")",
        };

        const discover_icon = {
            width: "30px",
            height: "30px",
            float: "left",
            marginRight: "10px",
            backgroundImage: "url(" + discoverImg + ")",
        }; */
        const year = new Date().getFullYear();
        
        return (
            <div className="footer">
                <footer className="page-footer">
                    <div className="container w-100-mob">

                        <div className="text-center">
                            <a href="/">
                                <img className="logo_img mb-3" src="/images/logo-white.svg" />
                            </a>
                            <p className="color-opacity">
                                VIP-Transfers / a Brand of{" "}
                                <a href="https://aratours.ch/" target="blank" className="text-white-opacity"> ARATOURS SA</a>
                            </p>
                        </div>

                    
                        <div className="d-flex flex-wrap justify-content-around my-4">
                            <a
                                className="footer-links"
                                href="/about"
                                itemProp="url"
                            >
                                About
                            </a>
                            <a
                                className="footer-links"
                                href="/terms"
                                itemProp="url"
                            >
                                Terms & Conditions
                            </a>
                            <a
                                className="footer-links"
                                href="/faq"
                                itemProp="url"
                            >
                                FAQ
                            </a>
                            <a
                                className="footer-links"
                                href="/contact"
                                itemProp="url"
                            >
                                Contact Us
                            </a>
                        </div>
                    
                        <div className="text-center">
                            <h3 className="footer_h3">Discover Us</h3>
                        </div>
                    
                        <div className="partner-icons">
                            <a href="https://alptransfer.com/" target="_blank">
                                <div className="footer_icon alp_icon"></div>
                            </a>
                            <a href="https://alpdelivery.ch/" target="_blank">
                                <div className="footer_icon alpdel_icon"></div>
                            </a>
                            <a href="https://transfer-airports.com/" target="_blank">
                                <div className="footer_icon trans_icon"></div>
                            </a>
                            <a href="http://eba-eurobus.com/" target="_blank">
                                <div className="footer_icon eba_icon"></div>
                            </a>
                            <a href="https://switzerland-tour.com/" target="_blank">
                                <div className="footer_icon swiss_icon"></div>
                            </a>
                            <a href="https://alpexcursion.com/" target="_blank">
                                <div className="footer_icon alpexc_icon"></div>
                            </a>
                        </div>
                    </div>

                    <div
                        className="container footer-link"
                        itemScope
                        itemType="http://www.schema.org/SiteNavigationElement"
                    >
                            <div className="footer-copyright justify-content-center flex-wrap">
                                © {year} |
                                <a className="span_color" href="/privacy"> Privacy Policy </a>
                                | Designed & developed by
                                <a
                                    className="span_color"
                                    href="https://alpwebmaster.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    AlpWebMaster.com
                                </a>
                            </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;