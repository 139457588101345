import { Component } from "react";
import React from "react";

import { Router, Route } from "react-router-dom";
import { Input } from "react-materialize";
//import MyGoogleMap from "../containers/Map";
import { GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
//import SweetAlert from 'react-bootstrap-sweetalert'
import { I18n } from "react-i18nify";
import { fetchMailContact } from "../services/RequestService";

import $ from "jquery";
import Home from "./Home";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.sendContactMail = this.sendContactMail.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        alert: false,
        alertClass: "alert-danger",
        alertMessage: null,
        alertType: null,
        name: "",
        email: "",
        message: "",
        phone: "",
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    hideAlert() {
        this.setState({
            alert: false,
        });
    }

    sendContactMail(e) {
        e.preventDefault();
        var email = this.mail.state.value;
        var tel = this.tel.state.value;
        var first_name = this.first_name.state.value;
        var message = this.message.state.value;
        let error = false;
        if ($(".error")) $(".error").remove();

        $(".required").each(function () {
            var id = $(this).attr("id");
            if (!$(this).val()) {
                $("#" + id)
                    .parent()
                    .append(
                        " <div class='error' style='width:100%;color: #ca0505'>This field is required</div>"
                    );
                error = true;
            }
            if (id == "mail" && $("#mail").val()) {
                var emailValid = $("#mail")
                    .val()
                    .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                if (!emailValid) {
                    $("#" + id)
                        .parent()
                        .append(
                            " <div class='error' style='width:100%;color: #ca0505'>Please enter a valid email address</div>"
                        );
                    error = true;
                    return false;
                }
            }
        });

        if (email && tel && first_name && message && !error) {
            let data = {
                url: "sendMailContact",
                email: email.replace(/\./g, "/"),
                tel: tel,
                message: message.replace(/\./g, "/"),
                first_name: first_name.split(" ").join("/"),
            };

            fetchMailContact(data).then((result) => {
                if (result && result.message == "success") {
                    console.log(result);
                    const successMessage = I18n.t("contact_success_message");
                    this.setState({
                        alert: true,
                        alertMessage: successMessage,
                        alertClass: "alert-success",
                        name: "",
                        email: "",
                        message: "",
                        phone: "",
                    });
                    $(".contact-form").css("opacity", "0.3");
                }
            });
        }
    }

    handleChangeName = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    handleChangeEmail = (event) => {
        this.setState({
            email: event.target.value,
        });
    };

    handleChangeMessage = (event) => {
        this.setState({
            message: event.target.value,
        });
    };

    handleChangePhone = (event) => {
        this.setState({
            phone: event.target.value,
        });
    };

    /* googleMap() {
        const google = this.props.google;

        return (
            <MyGoogleMap centerAroundCurrentLocation google={this.props.google}>
                <Marker
                    title={" VIP-Transfers Office in Fribourg"}
                    name={
                        "VIP-Transfer’s office is located in Fribourg - the heart of Switzerland."
                    }
                    onClick={this.onMarkerClick}
                    position={{ lat: 46.799549, lng: 7.15004 }}
                    icon={{
                        url: "/images/marker_icon.png",
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(32, 32),
                    }}
                />
                <Marker
                    title={" VIP-Transfers Fleet in Bern Airport"}
                    name={
                        "We set our fleet near to Bern Airport to reach you easily and on time."
                    }
                    onClick={this.onMarkerClick}
                    position={{ lat: 46.91222, lng: 7.49916 }}
                    icon={{
                        url: "/images/marker_icon.png",
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(32, 32),
                    }}
                />

                <Marker
                    title={" VIP-Transfers Fleet in Geneva Airport"}
                    name={
                        "We set our fleet near to Geneva Airport to reach you easily and on time."
                    }
                    onClick={this.onMarkerClick}
                    position={{ lat: 46.237, lng: 6.10915 }}
                    icon={{
                        url: "/images/marker_icon.png",
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(32, 32),
                    }}
                />

                <Marker
                    title={" VIP-Transfers Fleet in Zurich Airport"}
                    name={
                        "We set our fleet near to Zurich Airport to reach you easily and on time."
                    }
                    onClick={this.onMarkerClick}
                    position={{ lat: 47.45821, lng: 8.55547 }}
                    icon={{
                        url: "/images/marker_icon.png",
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(32, 32),
                    }}
                />

                <Marker
                    title={"VIP-Transfers Fleet in Basel Airport"}
                    name={
                        "We set our fleet near to Basel Airport to reach you easily and on time."
                    }
                    onClick={this.onMarkerClick}
                    position={{ lat: 47.59818, lng: 7.52549 }}
                    icon={{
                        url: "/images/marker_icon.png",
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(32, 32),
                    }}
                />

                <InfoWindow
                    options={{
                        maxWidth: 250,
                    }}
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose}
                >
                    <div className="info_window">
                        <p>{this.state.selectedPlace.name}</p>
                    </div>
                </InfoWindow>
            </MyGoogleMap>
        );
    } */

    onClose = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    render() {
        const google = this.props.google;

        var style = {
            display: "none",
        };
        if (this.state.alert) {
            style = {
                display: "block",
            };
        }
        
        return (
            <div>
                <div className="header-img contact_page">
                    <h1>Contact Us</h1>
                </div>
                
                <div className="container">
                    <div className="row">
                    <div className="col-md-10 col-lg-8 m-auto">

                        <div className={"alert " + this.state.alertClass} style={style}>
                            {this.state.alertMessage}.
                        </div>

                        <div className="contact-form pt-5">
                            <form className="form-horizontal">
                                <Input
                                    onChange={this.handleChangeName}
                                    value={this.state.name}
                                    s={12}
                                    ref={(c) => (this.first_name = c)}
                                    className="required"
                                    id="first_name"
                                    name="first_name"
                                    label="Name"
                                />
                                <Input
                                    onChange={this.handleChangeEmail}
                                    value={this.state.email}
                                    type="email"
                                    label="Email"
                                    ref={(c) => (this.mail = c)}
                                    className="required"
                                    id="mail"
                                    name="mail"
                                    s={12}
                                    validate
                                />
                                <Input
                                    onChange={this.handleChangePhone}
                                    value={this.state.phone}
                                    s={12}
                                    label="Telephone"
                                    id="tel"
                                    name="tel"
                                    ref={(c) => (this.tel = c)}
                                    className="required"
                                    type="tel"
                                    validate
                                />
                                <Input
                                    onChange={this.handleChangeMessage}
                                    value={this.state.message}
                                    type="textarea"
                                    ref={(c) => (this.message = c)}
                                    id="message"
                                    name="message"
                                    className="required"
                                    s={12}
                                    label="Message"
                                    validate
                                />
                                <div className="s12">
                                    <button
                                        id="contact_button"
                                        onClick={this.sendContactMail}
                                        type="submit"
                                        className="btn black_btn"
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className="contact-information row">
                            <h5 className="col-12 mb-4">
                                <b>VIP-Transfers</b> /{" "}
                                a Brand of <b>ARATOURS SA</b>
                            </h5>

                            <div className="contact-list col-md-5">
                                <i className="fa fa-calendar mr-2" />
                                Mon. - Sat.: 
                                <i className="fa fa-clock mr-2 ml-3" />
                                07h - 19h
                                <br />
                                
                                <i className="fa fa-phone mr-2" />
                                +41 58 580 0774
                                <br />
                                
                                <i className="fa fa-envelope mr-2" />
                                info@vip-transfers.ch
                            </div>
                               
                            <div className="col-md-7">
                                <i className="fa fa-map-marker-alt mr-2" />
                                <a target="_blank" href="https://maps.app.goo.gl/HuKoT7CdgjjT6L9RA" 
                                    class="text_grey"> Rue de la Banque 3, CH-1700 <b>- Fribourg</b></a>
                                <br />

                                <i className="fa fa-map-marker-alt mr-2" />
                                <a target="_blank" href="https://maps.app.goo.gl/rGxw8ZesAHWyuCRC6" 
                                    class="text_grey"> Rue des Moulières 5, CH-1242 Satigny <b>- Geneva</b></a>
                                <br />
                       
                                <i className="fa fa-map-marker-alt mr-2" />
                                <a target="_blank" href="https://maps.app.goo.gl/59ZNJAgmqoWfuzPV8" 
                                    class="text_grey"> Hohstrasse 1, CH-8302 Kloten <b>- Zurich</b></a>
                                <br />
                       
                                <i className="fa fa-map-marker-alt mr-2" />
                                <a target="_blank" href="https://maps.app.goo.gl/dyk1tHBJNtaCVEuU8" 
                                    class="text_grey"> Mühlestrasse 7, CH-3063 Ittigen <b>- Bern</b></a>
                                <br />

                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
