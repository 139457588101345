
export  const actionServices=(service)=>{

    return{
        type:'CHANGE_CONTENT_SERVICE',
        payload:service,
    }

};

export  const actionCars=(car)=>{
    return{
        type:'CHANGE_CONTENT_CAR',
        payload:car,
    }
};

export const getRoutesFrom= (points) => (

    {
        type: 'GET_ROUTES_FROM',
        payload:points
    }
);

