import { Component } from "react";
import React from "react";

import { Link } from "react-router-dom";
import { I18n } from "react-i18nify";
import connect from "react-redux/es/connect/connect";
import { actionServices } from "../actions/index";
import { bindActionCreators } from "redux";
import HomeServices from "../containers/home-services";
import HomeServicesMob from "../containers/home-services-mob";

class Services extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.updateHeaderClass('header_dark');
    }
    

    showServices() {
        const services = I18n.t("services");
        let classas = "";
        let style = {
            border: " ",
        };
        return Object.keys(services).map((index) => {
            if (
                this.props.activeService &&
                parseInt(this.props.activeService.id) == index
            ) {
                style = {
                    opacity: "1",
                };
                classas = "transition";
            } else {
                style = {
                    border: "",
                };
                classas = "without-trans";
            }
            const lang = this.props.activeLang.code;

            return (
                <div className="col-lg-2 col-4 service-item service-item-block" key={"col" + index}>
                    <Link
                        to={{
                            pathname:
                                "/service" + "/" + services[index].title_url,
                        }}
                        onClick={() => this.props.service(services[index])}
                    >
                        <div className="card service-card" key={"card" + index}>
                        <img
                                className={"card_service_mob d-lg-none " + classas}
                                key={"img" + index}
                                src={services[index].icon}
                            />
                            <div className="card-action" key={"card-action" + index}>
                                <p key={"p" + index}>{services[index].title}</p>
                            </div>
                            <div
                                style={style}
                                className="card-image service-icons d-none d-lg-block"
                                key={"car-img" + index}
                            >
                                <div className="rotate-brd rotate-brd-static">
                                    <img
                                        className={"card_service " + classas}
                                        key={"img" + index}
                                        src={services[index].icon}
                                    />
                                </div>
                                <div className="rotate-brd rotate-brd-hover">
                                    <img
                                        className={"card_service " + classas}
                                        src={services[index].iconHover}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });
    }

    render() {
        window.scrollTo(0, 0);
        let type = this.props.activeService ? this.props.activeService.type : null;
        let service = null;
        if (type && type == "GET_ROUTES_FROM") {
            service = {
                content: I18n.t("services")[0],
            };
        } else if (this.props.activeService && !type) {
            service = {
                content: this.props.activeService,
            };
        } else {
            service = {
                content: I18n.t("services")[0],
            };
        }
        return (
            <div className="container">
    
                    <div className="services_page_top">
                        <div className="col-6 d-none d-md-block">
                            <h1 className="section_title mb-md-3">WELCOME TO VIP-TRANSFERS:</h1>
                            <h2 className="section_subtitle text-left">Your Premier Swiss Travel Solution</h2>
                            <a href="https://booking.vip-transfers.ch/" className="btn mt-4 effecto fish white_fish"> Book Now </a>
                        </div>
                    </div>            

                    <h2 className="section_title">Services</h2>            
{/* 
                    <div className="content py-3">
                        <p className="home_p">
                            VIP-Transfers pleasurably welcomes you and your friends,
                            family members or relatives in Switzerland – the real
                            dreamland for travel lovers from the whole world, through the
                            wide options of the provided private services. <br />
                            <br />
                            Hereby, we are glad to announce and introduce you the list of
                            private services supplied by VIP-Transfers: <br />
                            
                        </p>
                    </div> */}
            
                    {/* <div className="row">{this.showServices()}</div> */}
                    <HomeServices col="col-lg-6" />
                            
                    <div className="content py-5">
                        {/* <p className="home_p">
                            You are highly advised to visit each one of our services’ pages separately in order to have a detailed background about them. <br />
                            <br />
                            VIP-Transfers’ motivated team is always at your disposal to answer all your inquiries and assist you by confirming your request, later, organising your preferred private transfer.<br />
                        </p> */}
                        <p className="home_p text-center">
                            Visit each service page for details and book your VIP-Transfers experience today!
                        </p>
                            
                    </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeLang: state.LangReducer.activeLang,
        activeService: state.actions,
    };
}

function matchDispatchToProps(dispach) {
    return bindActionCreators({ service: actionServices }, dispach);
}

export default connect(mapStateToProps, matchDispatchToProps)(Services);