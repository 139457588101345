import { Component } from "react";
import React from "react";

class Faq extends Component {
	render() {
		return (
			<div>
				<div className="header-img contact_page">
					<h1>FAQ</h1>
                </div>

				<div className="container py-5">
					<div
						className="panel-group"
						id="accordion"
						role="tablist"
						aria-multiselectable="true"
					>
						<div className="panel panel-default clicked">
							<div
								className="panel-heading active"
								role="tab"
								id="headingOne"
							>
								<h4 className="panel-title">
									<a
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										How can I make a booking?{" "}
									</a>
								</h4>
							</div>
							<div
								id="collapseOne"
								className="panel-collapse collapse in show"
								role="tabpanel"
								aria-labelledby="headingOne"
							>
								<div className="panel-body">
									Before making a booking, you are highly advised to
									acknowledge our company’s policy. You can make the
									booking both online and in written form, either by Fax
									or via e-mail. Reservation should be submitted 24
									(twenty-four) hours before your arrival. For the
									last-minute bookings, you will receive confirmation
									upon our agreement due to our schedule and service
									availability.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingTwo">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										What happens if I fail to place an online booking?
									</a>
								</h4>
							</div>
							<div
								id="collapseTwo"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingTwo"
							>
								<div className="panel-body">
									You are respectfully advised to email us your
									reservation inquiry for our consideration. Within a
									short time, the operator will assist you to place the
									booking with the necessary information. Afterwards,
									you will receive your booking confirmation voucher.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingThree"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Do infants count?
									</a>
								</h4>
							</div>
							<div
								id="collapseThree"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingThree"
							>
								<div className="panel-body">
									Infants from (0 -2) and children (3-11) of age count
									in all vehicles, as they occupy a place in the car.
									They need a special child seat / booster.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingFour"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Do you provide child seats if needed?
									</a>
								</h4>
							</div>
							<div
								id="collapseFour"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingFour"
							>
								<div className="panel-body">
									Any required car can be accommodated with a child seat
									/ a booster upon request.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingFive"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										What kind of vehicles do you provide?
									</a>
								</h4>
							</div>
							<div
								id="collapseFive"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingFive"
							>
								<div className="panel-body">
									You can be acquainted with our vehicles by visiting
									the “Fleet” section of our web page.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingSix">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseSix"
										aria-expanded="false"
										aria-controls="collapseSix"
									>
										Do you provide shared transfers?
									</a>
								</h4>
							</div>
							<div
								id="collapseSix"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingSix"
							>
								<div className="panel-body">
									Our company does not provide shared transportation.
									Only private transfers are at disposal.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingSeven"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseSeven"
										aria-expanded="false"
										aria-controls="collapseSeven"
									>
										What are the payment types?
									</a>
								</h4>
							</div>
							<div
								id="collapseSeven"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingSeven"
							>
								<div className="panel-body">
									Payments can be made by Credit Card / PayPal, Bank
									Transfer (at least 14 days before your arrival). The
									payment is non-refundable if changes are made after 48
									hours before your arrival.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingEight"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseEight"
										aria-expanded="false"
										aria-controls="collapseEight"
									>
										Do you provide “Meet” & “Greet “service?
									</a>
								</h4>
							</div>
							<div
								id="collapseEight"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingEight"
							>
								<div className="panel-body">
									We provide you with “Meet” & “Greet” service upon your
									arrival.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingNine"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseNine"
										aria-expanded="false"
										aria-controls="collapseNine"
									>
										How should I find the driver?
									</a>
								</h4>
							</div>
							<div
								id="collapseNine"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingNine"
							>
								<div className="panel-body">
									Our drivers always wait at the arrival hall inside the
									airport, holding the sign with the leading passenger’s
									name.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingTen">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseTen"
										aria-expanded="false"
										aria-controls="collapseTen"
									>
										What should I do if I fail to find the driver?
									</a>
								</h4>
							</div>
							<div
								id="collapseTen"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingTen"
							>
								<div className="panel-body">
									In case if you fail to find the driver, you should
									contact us via our emergency number +41 58 580 0774,
									available around the clock.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingEleven"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseEleven"
										aria-expanded="false"
										aria-controls="collapseEleven"
									>
										What do I need to know before canceling my booking?
									</a>
								</h4>
							</div>
							<div
								id="collapseEleven"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingEleven"
							>
								<div className="panel-body">
									Booking cancellation due to some circumstances beyond
									your control must be submitted 48 (forty-eight) hours
									or more prior to the commencement of the transfer. The
									whole amount, already paid to the company, shall be
									reimbursed. No refund is applicable if the booking
									cancellation is notified later than 48 (forty-eight),
									or on the last-minute.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div
								className="panel-heading"
								role="tab"
								id="headingTwelve"
							>
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapseTwelve"
										aria-expanded="false"
										aria-controls="collapseTwelve"
									>
										What if I want to change the details of my booking?
									</a>
								</h4>
							</div>
							<div
								id="collapseTwelve"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="headingTwelve"
							>
								<div className="panel-body">
									You can change the details upon 24 hours in advance
									notification. The last-minute changes are subject to
									agreement.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading13">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse13"
										aria-expanded="false"
										aria-controls="collapse13"
									>
										Do you provide handicapped service?
									</a>
								</h4>
							</div>
							<div
								id="collapse13"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading13"
							>
								<div className="panel-body">
									Yes, our company assures and utilizes vehicles for
									passengers with special needs.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading14">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse14"
										aria-expanded="false"
										aria-controls="collapse14"
									>
										When can I make the booking?
									</a>
								</h4>
							</div>
							<div
								id="collapse14"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading14"
							>
								<div className="panel-body">
									You can make the reservation 24 hours prior to your
									arrival date. Bookings placed on the last-minute are
									subject to agreement.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading15">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse15"
										aria-expanded="false"
										aria-controls="collapse15"
									>
										How should I notify the different pickup/drop off
										place?
									</a>
								</h4>
							</div>
							<div
								id="collapse15"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading15"
							>
								<div className="panel-body">
									You should clearly outline the different pickup/drop
									off place in the field of “special requests” in your
									booking inquiry.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading16">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse16"
										aria-expanded="false"
										aria-controls="collapse16"
									>
										Different members of the same group arrive at
										different times. How should I submit the booking?
									</a>
								</h4>
							</div>
							<div
								id="collapse16"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading16"
							>
								<div className="panel-body">
									If your group is divided into two (or more) parts, and
									some of the members are arriving at different times,
									but you desire to have the same transfer for the whole
									group, you are advised to make the booking pickup time
									according to the last arrival flight.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading17">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse17"
										aria-expanded="false"
										aria-controls="collapse17"
									>
										Do your provided prices include all taxes?
									</a>
								</h4>
							</div>
							<div
								id="collapse17"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading17"
							>
								<div className="panel-body">
									Our quotation includes all charges such as VAT,
									luggage, child seat and waiting time in case of late
									arrival (up to 1 hour waiting time is free of charge).
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading18">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse18"
										aria-expanded="false"
										aria-controls="collapse18"
									>
										Do you provide a car at disposal with/without a
										driver?
									</a>
								</h4>
							</div>
							<div
								id="collapse18"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading18"
							>
								<div className="panel-body">
									Our fleet is at disposal only with the accompaniment
									of experienced and well-mannered drivers.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading19">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse19"
										aria-expanded="false"
										aria-controls="collapse19"
									>
										What is your smoking/drinking policy?
									</a>
								</h4>
							</div>
							<div
								id="collapse19"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading19"
							>
								<div className="panel-body">
									Smoking and consumption of alcohol and spirits are
									strictly prohibited in the company’s vehicles.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading20">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse20"
										aria-expanded="false"
										aria-controls="collapse20"
									>
										How much luggage is allowed for a passenger?
									</a>
								</h4>
							</div>
							<div
								id="collapse20"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading20"
							>
								<div className="panel-body">
									Once you place a booking, you are advised to declare
									your excess baggage. Two items: medium and small sized
									ones, as well as a ski and a snowboard bag are
									applicable for one passenger. Any extra excess luggage
									must be submitted and notified in advance, in order to
									utilize the right vehicle.
									<ul>
										<li>Small_ 55 x 40 x 23</li>
										<br />
										<li>Medium_ 70 x 45 x 25</li>
										<br />
										<li>Large_ 80 x 50 x 30</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading21">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse21"
										aria-expanded="false"
										aria-controls="collapse21"
									>
										Waiting time
									</a>
								</h4>
							</div>
							<div
								id="collapse21"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading21"
							>
								<div className="panel-body">
									All possible delays and changes related to the
									arrival/departure time must be notified in advance.
									There would be no any extra charge for 1 hour waiting
									time. For all other cases of delays notified later
									than it is required, extra charges are applicable. For
									more information visit Terms & Conditions, section
									Contact procedure / Waiting Time / No-show transfers.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading22">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse22"
										aria-expanded="false"
										aria-controls="collapse22"
									>
										Do you provide an invoice for the required transfer?
									</a>
								</h4>
							</div>
							<div
								id="collapse22"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading22"
							>
								<div className="panel-body">
									We always provide the customers with the necessary
									invoice submitted with transfer and price details.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading23">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse23"
										aria-expanded="false"
										aria-controls="collapse23"
									>
										Do you make transfers to car-free resorts?
									</a>
								</h4>
							</div>
							<div
								id="collapse23"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading23"
							>
								<div className="panel-body">
									We organize transfers to car-free resorts upon in
									advance request.
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading24">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse24"
										aria-expanded="false"
										aria-controls="collapse24"
									>
										Will you make a refund if I miss the transfer?
									</a>
								</h4>
							</div>
							<div
								id="collapse24"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading24"
							>
								<div className="panel-body">
									If the customer is not provided with initially
									confirmed service and misses the transfer because of
									the inaccurate approach of the company, a full refund
									is applicable. For more information, visit Terms &
									Condition, section "Cancellations".
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="heading25">
								<h4 className="panel-title">
									<a
										className="collapsed"
										role="button"
										data-toggle="collapse"
										data-parent="#accordion"
										href="#collapse25"
										aria-expanded="false"
										aria-controls="collapse25"
									>
										Do you make up a tour itinerary?
									</a>
								</h4>
							</div>
							<div
								id="collapse25"
								className="panel-collapse collapse"
								role="tabpanel"
								aria-labelledby="heading25"
							>
								<div className="panel-body">
									A tour itinerary can be outlined due to the customer’s
									basic inquiry with the primary details such as
									destinations, the dates, and preferred car model as
									well as car disposal hours.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Faq;